include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm(data-test='theme-selector')
  .fp-vertical-spacing--line
    span.fp-text.-text-sm.-text-semi-bold {{ t('theme') }}
  .fp-vertical-spacing--line
    .fp-horizontal-spacing.-s-sm
      .fp-horizontal-spacing--column(data-test='light' @click='setTheme("light")')
        a.fp-link
          +fp-icon('sun')
      .fp-horizontal-spacing--column(data-test='dark' @click='setTheme("dark")')
        a.fp-link
          +fp-icon('moon')

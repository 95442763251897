import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ExclusiveAuctionBidVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-bid';

export default defineComponent({
  name: 'ExclusiveDetailHeading',

  components: { ExclusivePriceVue, ExclusiveAuctionBidVue },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    return { t, club };
  },
});

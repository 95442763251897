import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusivesFilters, SortBy, SortByOrder, SortOrder } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';
import { LocationQuery, LocationQueryRaw, LocationQueryValue } from 'vue-router';

const getCategoriesFilters = (categoriesFilters: LocationQueryValue | Array<LocationQueryValue>): Array<ExclusiveCategory> => {
  const categories = categoriesFilters?.toString();
  if (!categories) {
    return [ExclusiveCategory.EXPERIENCE, ExclusiveCategory.COLLECTIBLE];
  }
  return categories.split(',') as Array<ExclusiveCategory>;
};

export const getFilters = (query: LocationQuery): ExclusivesFilters => {
  const categories = getCategoriesFilters(query.categories);
  const querySort = (query['sort'] as SortByOrder) || undefined;
  return {
    categories,
    sortBy: (querySort?.split(':')?.[0] as SortBy) || 'price',
    sortOrder: (querySort?.split(':')?.[1] as SortOrder) || 'desc',
  };
};

export const filtersToSave = (exclusiveFilters: ExclusivesFilters): LocationQueryRaw => {
  const sortOrder = exclusiveFilters.sortOrder;
  const sortBy = exclusiveFilters.sortBy;
  const sort = `${sortBy}:${sortOrder}` === 'price:desc' ? undefined : `${sortBy}:${sortOrder}`;

  if (hasFiltersCategories(exclusiveFilters)) {
    return {
      categories: exclusiveFilters.categories.join(','),
      sort,
    };
  }
  return {
    sort,
  };
};

export const isDefaultFiltersCase = (exclusivesFilters: ExclusivesFilters): boolean => {
  const sortOrder = exclusivesFilters.sortOrder;
  const sortBy = exclusivesFilters.sortBy;

  return sortOrder === 'desc' && sortBy === 'price';
};

export const hasExclusivesFilters = (exclusivesFilters: ExclusivesFilters): boolean => {
  if (!isDefaultFiltersCase(exclusivesFilters)) {
    return true;
  }
  return hasFiltersCategories(exclusivesFilters);
};

export const hasFiltersCategories = (exclusivesFilters: ExclusivesFilters) => {
  const currentCategories = exclusivesFilters.categories;
  const currentCategoriesLength = currentCategories.length;
  const categoriesLength = Object.keys(ExclusiveCategory).length;

  if (currentCategoriesLength === 0) {
    return false;
  }

  return currentCategoriesLength !== categoriesLength;
};

import { inject, onMounted, onUnmounted, ref } from 'vue';
import { globalWindowKey } from '@/common/domain/Window';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { HighlightedMediaList } from '@/common/domain/highlighted-media/HighlightedMediaList';

const HIGHLIGHTED_MEDIA_SLIDE_OUT_DURATION = 300;

export default {
  name: 'HighlightedMedia',

  setup() {
    const globalWindow = inject(globalWindowKey)!;
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const slidingRight = ref<boolean>(false);
    const slidingLeft = ref<boolean>(false);
    const isMediaHighlighted = ref<boolean>(false);
    const medias = ref<HighlightedMediaList>();
    let unsubscribeOpenModal: Unsubscribe;
    let unsubscribeCloseModal: Unsubscribe;

    const handleKeyup = (event: KeyboardEvent): void => {
      if (isMediaHighlighted.value) {
        if (event.key === 'Escape') {
          highlightedMediaBus.close();
        }
        if (event.key === 'ArrowLeft') {
          previous();
        }
        if (event.key === 'ArrowRight') {
          next();
        }
      }
    };

    onMounted(async () => {
      globalWindow.document.addEventListener('keyup', handleKeyup);
      unsubscribeOpenModal = highlightedMediaBus.opened(openHighlightedMedia);
      unsubscribeCloseModal = highlightedMediaBus.closed(closeHighlightedMedia);
    });

    onUnmounted(() => {
      globalWindow.document.removeEventListener('keyup', handleKeyup);
      unsubscribeOpenModal();
      unsubscribeCloseModal();
    });

    const openHighlightedMedia = (mediasList: HighlightedMediaList) => {
      medias.value = mediasList;
      isMediaHighlighted.value = true;
    };

    const closeHighlightedMedia = () => {
      isMediaHighlighted.value = false;
    };

    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    const next = (event?: MouseEvent) => {
      event?.stopPropagation();
      if (medias.value!.activeIndex !== medias.value!.medias.length - 1) {
        slidingLeft.value = true;
        globalWindow.setTimeout(() => {
          medias.value!.activeIndex = medias.value!.activeIndex + 1;
          slidingLeft.value = false;
        }, HIGHLIGHTED_MEDIA_SLIDE_OUT_DURATION);
      }
    };

    const previous = (event?: MouseEvent) => {
      event?.stopPropagation();
      if (medias.value!.activeIndex !== 0) {
        slidingRight.value = true;
        globalWindow.setTimeout(() => {
          medias.value!.activeIndex = medias.value!.activeIndex - 1;
          slidingRight.value = false;
        }, HIGHLIGHTED_MEDIA_SLIDE_OUT_DURATION);
      }
    };

    return {
      slidingLeft,
      slidingRight,
      isMediaHighlighted,
      medias,
      handleKeyup,
      previous,
      next,
      openHighlightedMedia,
      closeHighlightedMedia,
      stopPropagation,
    };
  },
};

import { GiveawayParticipationToRegister } from '@/fairplayer/domain/giveaway/GiveawayParticipationToRegister';
import {
  BackendGiveawayAnsweredQuestion,
  fromGiveawayAnsweredQuestion,
} from '@/fairplayer/secondary/giveaway/BackendGiveawayAnsweredQuestion';

export interface BackendGiveawayParticipationToRegister {
  firstName: string;
  lastName: string;
  email: string;
  answeredQuestions: BackendGiveawayAnsweredQuestion[];
  partnerSlug?: string;
}

export const fromGiveawayParticipationToRegister = (
  giveawayParticipation: GiveawayParticipationToRegister
): BackendGiveawayParticipationToRegister => ({
  firstName: giveawayParticipation.firstName,
  lastName: giveawayParticipation.lastName,
  email: giveawayParticipation.email,
  answeredQuestions: giveawayParticipation.answeredQuestions.map(fromGiveawayAnsweredQuestion),
  partnerSlug: giveawayParticipation.partnerSlug.orElseUndefined(),
});

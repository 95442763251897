import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';
import { Optional } from '@/common/domain/Optional';

export interface BackendGiveawayParticipation {
  id: string;
  giveawayId: string;
  createdAt: string;
  partnerSlug?: string;
}

export const toGiveawayParticipation = (backendGiveawayParticipation: BackendGiveawayParticipation): GiveawayParticipation => ({
  id: backendGiveawayParticipation.id,
  giveawayId: backendGiveawayParticipation.giveawayId,
  createdAt: new Date(backendGiveawayParticipation.createdAt),
  partnerSlug: Optional.ofUndefinable(backendGiveawayParticipation.partnerSlug),
});

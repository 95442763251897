import { Optional } from '@/common/domain/Optional';
import { ExclusivesFilters } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';
import { InjectionKey } from 'vue';

const EXCLUSIVES_FILTERS = 'exclusives_filters';

export const exclusivesFiltersStorageKey: InjectionKey<ExclusivesFiltersStorage> = Symbol();

export class ExclusivesFiltersStorage {
  constructor(private storage: Storage) {}

  save(exclusivesFilters: ExclusivesFilters) {
    this.storage.setItem(EXCLUSIVES_FILTERS, JSON.stringify(exclusivesFilters));
  }

  get(): Optional<ExclusivesFilters> {
    return Optional.ofUndefinable(this.storage.getItem(EXCLUSIVES_FILTERS)).map(JSON.parse);
  }
}

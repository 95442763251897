import { BackendCaller } from '@/common/secondary/BackendCaller';
import { GiveawayParticipationRepository } from '@/fairplayer/domain/giveaway/GiveawayParticipationRepository';
import { GiveawayParticipationToRegister } from '@/fairplayer/domain/giveaway/GiveawayParticipationToRegister';
import { fromGiveawayParticipationToRegister } from '@/fairplayer/secondary/giveaway/BackendGiveawayParticipationToRegister';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';
import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';
import { BackendGiveawayParticipation, toGiveawayParticipation } from '@/fairplayer/secondary/giveaway/BackendGiveawayParticipation';

export class BackendGiveawayParticipationRepository implements GiveawayParticipationRepository {
  constructor(private backendCaller: BackendCaller) {}

  listFor(clubSlug: ClubSlug, fanUsername: Username): Promise<GiveawayParticipation[]> {
    return this.backendCaller
      .get<BackendGiveawayParticipation[]>(`/api/clubs/${clubSlug}/fans/${encodeURIComponent(fanUsername)}/giveaway-participations`)
      .then(participations => participations.data.map(toGiveawayParticipation));
  }

  async register(giveawayParticipationToRegister: GiveawayParticipationToRegister): Promise<void> {
    await this.backendCaller.post(
      `/api/clubs/${giveawayParticipationToRegister.clubSlug}/giveaway-participations`,
      fromGiveawayParticipationToRegister(giveawayParticipationToRegister)
    );
  }
}

import { Fiat } from '@/common/domain/token/Fiat';
import { fromSymbol } from '@/common/domain/token/Currency';

export interface BackendFiat {
  value: number;
  currency: string;
}

export const toFiat = (backendFiat: BackendFiat): Fiat => ({
  value: backendFiat.value,
  currency: fromSymbol(backendFiat.currency),
});

import { AlertType } from '@/common/domain/alert/AlertType';
import { globalWindowKey } from '@/common/domain/Window';
import { ToastUi } from '@/fairplayer/primary/toasts/Toast.ui';
import { computed, defineComponent, inject, onMounted, PropType, ref } from 'vue';

const TIMEOUT_DELAY = 5000;

const icons: Record<AlertType, string> = {
  danger: 'close-circle',
  info: 'info',
  success: 'check-circle',
  warning: 'warning',
};

export default defineComponent({
  name: 'Toast',

  props: {
    toast: {
      type: Object as PropType<ToastUi>,
      required: true,
    },
  },

  emits: ['closed'],

  setup(props, { emit }) {
    const globalWindow = inject(globalWindowKey)!;

    const timeoutId = ref(0);

    const icon = computed<string>(() => `fp-glyph-${icons[props.toast.type]}`);

    const close = () => {
      emit('closed');
    };

    const enter = () => {
      globalWindow.clearTimeout(timeoutId.value);
    };

    const leave = () => {
      delayClose();
    };

    const delayClose = () => {
      timeoutId.value = globalWindow.setTimeout(() => close(), TIMEOUT_DELAY);
    };

    onMounted(() => {
      delayClose();
    });

    return { close, enter, icon, leave, timeoutId };
  },
});

import { defineComponent, PropType } from 'vue';
import { ClubSummaryUi } from '@/fairplayer/primary/homepage/club-selection/ClubSummary.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LinkedClubBanner',

  props: {
    club: {
      type: Object as PropType<ClubSummaryUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

import { defineComponent, PropType } from 'vue';
import { GiveawayAnsweredQuestionUi } from '@/fairplayer/primary/giveaway-participation/giveaway-participation-quiz/GiveawayAnsweredQuestion.ui';
import { useI18n } from 'vue-i18n';
import { GiveawayQuestionUi } from '@/fairplayer/primary/club/GiveawayQuestion.ui';
export default defineComponent({
  name: 'GiveawayParticipationQuiz',

  props: {
    questions: {
      type: Array as PropType<GiveawayQuestionUi[]>,
      required: true,
    },
    formDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['answered'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const selectAnswered = (questionId: string, answerId: string) => {
      const answeredQuestion: GiveawayAnsweredQuestionUi = { questionId, answerId };

      emit('answered', answeredQuestion);
    };

    const inputAnswered = (questionId: string, answerText: string) => {
      const answeredQuestion: GiveawayAnsweredQuestionUi = { questionId, answerText };

      emit('answered', answeredQuestion);
    };

    return {
      selectAnswered,
      inputAnswered,
      t,
    };
  },
});

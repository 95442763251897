import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { Operation } from '@/fairplayer/domain/operation/Operation';
import { OperationType } from '@/fairplayer/domain/operation/OperationType';

export interface BackendOperation {
  cost: BackendPrice;
  type: OperationType;
  date: string;
}

export const toOperation = (backendOperation: BackendOperation): Operation => ({
  type: backendOperation.type,
  pricing: toTokens(backendOperation.cost),
  date: new Date(backendOperation.date),
});

const sortByDateDesc = () => (operation1: Operation, operation2: Operation) => operation2.date.getTime() - operation1.date.getTime();

export const toOperations = (backendOperations: BackendOperation[]): Operation[] =>
  backendOperations.map(operation => toOperation(operation)).sort(sortByDateDesc());

const invalidDateTime = () => {
  throw new Error('LocalDateTime should be in valid format: YYYY-MM-DDTHH:mm');
};

const assertValidDateTime = (iso: string) => {
  if (iso === '') {
    invalidDateTime();
  }

  if (isNaN(Date.parse(iso))) {
    invalidDateTime();
  }

  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (!isoRegex.test(iso)) {
    invalidDateTime();
  }
};

export class LocalDateTime {
  readonly year: number;
  readonly month: number;
  readonly day: number;
  readonly hour: number;
  readonly minute: number;

  constructor(readonly iso: string) {
    const trimIso = iso.trim();
    assertValidDateTime(trimIso);

    const [date, time] = trimIso.split('T');

    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');

    this.year = +year;
    this.month = +month;
    this.day = +day;
    this.hour = +hour;
    this.minute = +minute;
  }

  static of(iso: string): LocalDateTime {
    return new LocalDateTime(iso);
  }

  getIsoLocalDate(): string {
    return this.iso.slice(0, 10);
  }

  getIsoTime(): string {
    return this.iso.slice(11, 16);
  }
}

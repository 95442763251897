import { GiveawayAnsweredQuestion } from '@/fairplayer/domain/giveaway/GiveawayAnsweredQuestion';
import { Optional } from '@/common/domain/Optional';

export interface GiveawayAnsweredQuestionUi {
  questionId: string;
  answerId?: string;
  answerText?: string;
}

export const toGiveawayAnsweredQuestion = (answeredQuestion: GiveawayAnsweredQuestionUi): GiveawayAnsweredQuestion => ({
  questionId: answeredQuestion.questionId,
  answer: { id: Optional.ofEmpty(answeredQuestion.answerId), text: Optional.ofEmpty(answeredQuestion.answerText) },
});

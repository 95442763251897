import { ClubUi } from '@/fairplayer/primary/club/Club.ui';
import { MenuItemUi } from '@/common/primary/MenuItem.ui';
import { RouterTab } from '@/router/RouterTab';

const profileItem: MenuItemUi = {
  routeName: 'profile',
  translateKey: 'profile',
  slug: 'profile',
  icon: 'user',
  routerTab: RouterTab.PROFILE,
};

const homepage: MenuItemUi = {
  routeName: 'clubHomepage',
  translateKey: 'clubHomepage',
  slug: 'club-homepage',
  icon: 'home',
  routerTab: RouterTab.HOMEPAGE,
};

const wallet: MenuItemUi = {
  routeName: 'wallet',
  translateKey: 'wallet',
  slug: 'wallet',
  icon: 'wallet',
  routerTab: RouterTab.WALLET,
};

const programs: MenuItemUi = {
  routeName: 'programs',
  translateKey: 'programs',
  slug: 'programs',
  icon: 'newspaper',
  routerTab: RouterTab.PROGRAMS,
};

const members: MenuItemUi = {
  routeName: 'membersList',
  translateKey: 'members',
  slug: 'members',
  icon: 'identification-badge',
  routerTab: RouterTab.MEMBERS,
};

const marketplace: MenuItemUi = {
  routeName: 'marketplace',
  translateKey: 'marketplace',
  slug: 'marketplace',
  icon: 'shopping-bag',
  routerTab: RouterTab.MARKETPLACE,
};

const news: MenuItemUi = {
  routeName: 'newsfeed',
  translateKey: 'news',
  slug: 'newsfeed',
  icon: 'calendar-check',
  routerTab: RouterTab.NEWS,
};

const donations: MenuItemUi = {
  routeName: 'donationPage',
  translateKey: 'donations',
  slug: 'donations',
  icon: 'hand-heart',
  routerTab: RouterTab.DONATIONS,
};

const extraMenuItems = (clubUi: ClubUi): MenuItemUi[] => {
  if (clubUi.isFoundation) {
    return foundationExtraMenuItems(clubUi);
  }

  return marketplaceExtraMenuItems(clubUi);
};

const foundationExtraMenuItems = (clubUi: ClubUi) => {
  if (clubUi.referralNeeded) {
    return [members, programs, news];
  }

  return [donations, programs, news];
};

const marketplaceExtraMenuItems = (clubUi: ClubUi) => {
  if (!clubUi.coinsEnabled) {
    return [marketplace];
  }

  return [wallet, marketplace];
};

export const createMenuItems = (clubUi: ClubUi, authenticated: boolean, fanValidated: boolean): MenuItemUi[] => {
  if (!authenticated) {
    return [homepage, ...extraMenuItems(clubUi)];
  }

  if (!fanValidated) {
    return [];
  }

  return [homepage, ...extraMenuItems(clubUi), profileItem];
};

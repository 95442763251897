import { defineComponent, inject, PropType, ref } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useRouter } from 'vue-router';
import { NewPasswordVue } from '@/common/primary/auth/new-password';
import { NewPasswordUpdated } from '@/common/primary/auth/new-password/NewPasswordUpdated';

export default defineComponent({
  name: 'ResetPassword',
  components: { FairplayerButtonVue, NewPasswordVue },
  props: {
    email: {
      type: String as PropType<string>,
      required: true,
    },
  },

  setup(props) {
    const authentication = inject(authenticationKey)!;
    const router = useRouter();
    const { t } = useI18n();
    const invalidResetPassword = ref(false);
    const isResettingPassword = ref(false);
    const newPassword = ref('');
    const confirmationCode = ref('');

    const disabledResetPassword = ref(true);

    const confirmResetPassword = async () => {
      invalidResetPassword.value = false;
      isResettingPassword.value = true;

      authentication
        .confirmResetPassword({ email: props.email, password: newPassword.value }, confirmationCode.value)
        .then(async () => await router.push({ name: 'login' }))
        .catch(() => (invalidResetPassword.value = true))
        .finally(() => (isResettingPassword.value = false));
    };

    const updateNewPassword = (update: NewPasswordUpdated) => {
      newPassword.value = update.password;
      disabledResetPassword.value = !update.valid;
    };

    return {
      disabledResetPassword,
      invalidResetPassword,
      isResettingPassword,
      confirmResetPassword,
      confirmationCode,
      t,
      updateNewPassword,
    };
  },
});

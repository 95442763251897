import { fromOperation, OperationUi } from '@/fairplayer/primary/wallet/operations-list/Operation.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Operation } from '@/fairplayer/domain/operation/Operation';
import { operationRepositoryKey } from '@/fairplayer/domain/operation/OperationRepository';
import { Loader } from '@/loader/primary/Loader';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';

export default {
  name: 'OperationsList',

  setup() {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const operationRepository = inject(operationRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const operationsList = ref(Loader.loading<OperationUi[]>());

    onMounted(async () => {
      let operationListResponse: Operation[] = [];

      try {
        operationListResponse = await operationRepository.list(clubRepository.getCurrentSlug());
      } catch (error: any) {
        logger.error('Failed to retrieve operation list', error);
      } finally {
        operationsList.value.loaded(operationListResponse.map(fromOperation));
      }
    });

    return {
      operationsList,
      t,
    };
  },
};

import { authenticationKey } from '@/common/domain/auth/Authentication';
import { FairplayerButtonVue } from '@/common/primary/button';
import { NotFoundVue } from '@/common/primary/not-found';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FeatureCardListVue } from '@/fairplayer/primary/home/club-homepage/feature-card-list';
import { UpcomingClubAlertVue } from '@/fairplayer/primary/home/club-homepage/upcoming-club-alert';
import { LoginVue } from '@/common/primary/auth/login';
import { LinkedClubBannerVue } from '@/fairplayer/primary/club/linked-club-banner';
import { loggerKey } from '@/common/domain/Logger';
import { FaqVue } from './faq';
import { fromProgram, ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { programRepositoryKey } from '@/fairplayer/domain/program/ProgramRepository';
import { Program } from '@/fairplayer/domain/program/Program';
import { ClubHomepageHeaderVue } from '@/fairplayer/primary/home/club-homepage/club-homepage-header';
import { ExclusivesCarouselVue } from '@/fairplayer/primary/exclusive-carousel';
import { MembersCarouselVue } from '@/fairplayer/primary/members-carousel';
import { memberRepositoryKey } from '@/fairplayer/domain/member/MemberRepository';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { fromPost, PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { postRepositoryKey } from '@/fairplayer/domain/post/PostRepository';
import { Post } from '@/fairplayer/domain/post/Post';
import { PostsListVue } from '@/fairplayer/primary/home/club-homepage/posts-list';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { ProgramsCarouselVue } from '@/fairplayer/primary/program-carousel';
import { fromMember, MemberUi } from '@/fairplayer/primary/members-page/Member.ui';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';

export default defineComponent({
  name: 'ClubHomepage',

  components: {
    ClubHomepageHeaderVue,
    ExclusivesCarouselVue,
    FairplayerButtonVue,
    FeatureCardListVue,
    LinkedClubBannerVue,
    LoginVue,
    MembersCarouselVue,
    NotFoundVue,
    FaqVue,
    PageVue,
    PostsListVue,
    ProgramsCarouselVue,
    UpcomingClubAlertVue,
  },

  setup() {
    const { t } = useI18n();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const memberRepository = inject(memberRepositoryKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const postRepository = inject(postRepositoryKey)!;

    const isAuthenticated = ref(Loader.loading<boolean>());
    const exclusives = ref(Loader.loading<Exclusive[]>());
    const likes = ref(Loader.loading<ExclusiveId[]>());
    const carouselExclusives = computed(() =>
      exclusives.value
        .value()
        .map(exclusive =>
          fromExclusive(exclusive, clubRepository.getCurrentClub(), fan.value?.id, likes.value.value().includes(exclusive.id))
        )
    );
    const exclusivesWithAuction = computed(() =>
      exclusives.value
        .value()
        .filter(exclusive => exclusive.auctionLot.isPresent())
        .filter(exclusive => exclusive.stock > 0)
        .map(exclusive =>
          fromExclusive(
            { ...exclusive, pricing: exclusive.auctionLot.orElseThrow().currentPrice },
            clubRepository.getCurrentClub(),
            fan.value?.id,
            likes.value.value().includes(exclusive.id)
          )
        )
    );
    const exclusivesWithoutAuction = computed(() =>
      exclusives.value
        .value()
        .filter(exclusive => exclusive.auctionLot.isEmpty())
        .filter(exclusive => exclusive.stock > 0)
        .map(exclusive =>
          fromExclusive(exclusive, clubRepository.getCurrentClub(), fan.value?.id, likes.value.value().includes(exclusive.id))
        )
    );
    const programs = ref(Loader.loading<ProgramUi[]>());
    const members = ref(Loader.loading<MemberUi[]>());
    const posts = ref(Loader.loading<PostUi[]>());

    const club = fromClub(clubRepository.getCurrentClub());
    const showTokensDocumentation = club.coinsEnabled;
    const fan = ref<Fan>();
    const fanLanguage = fanLanguageRepository.getCurrentLanguage();

    const hasExclusives = computed(() => !exclusives.value.isLoading() && exclusives.value.value().length > 0);
    const hasPrograms = computed(() => !programs.value.isLoading() && programs.value.value().length > 0);
    const hasAuctions = computed(() => !exclusives.value.isLoading() && exclusivesWithAuction.value.length > 0);
    const hasExclusiveWithoutAuctions = computed(() => !exclusives.value.isLoading() && exclusivesWithoutAuction.value.length > 0);
    const hasPosts = computed(() => !posts.value.isLoading() && posts.value.value().length);
    const hasMembers = computed(() => !members.value.isLoading() && members.value.value().length > 0);
    const isNotReferred = computed(() => fan.value !== undefined && club.referralNeeded && fan.value.kycStatus === KycStatus.NOT_REFERRED);
    const isValidated = computed(() => fan.value !== undefined && fan.value.kycStatus === KycStatus.VALIDATED);
    const displayAppContent = computed(
      () => !club.referralNeeded || (fan.value !== undefined && fan.value.kycStatus !== KycStatus.NOT_REFERRED)
    );

    const retrieveFan = async () => {
      await fanRepository
        .getForClub(clubRepository.getCurrentClub())
        .then((retrievedFan: Fan) => (fan.value = retrievedFan))
        .catch((error: any) => {
          logger.error('Failed to retrieve fan', error);
        });
    };

    const retrieveAuthentication = async () => {
      await authentication
        .isAuthenticated()
        .then((authenticated: boolean) => isAuthenticated.value.loaded(authenticated))
        .catch((error: any) => {
          logger.error('Failed to authenticate', error);
          isAuthenticated.value.loaded(false);
        });

      if (isAuthenticated.value.value()) {
        await retrieveFan();
      }
    };

    const retrieveExclusives = async () => {
      let likesPromise: Promise<ExclusiveId[]> = Promise.resolve([]);
      if (isAuthenticated.value.value()) {
        likesPromise = exclusiveRepository.listLikes(clubRepository.getCurrentSlug());
      }

      await Promise.all([exclusiveRepository.listBySlug(clubRepository.getCurrentSlug()), likesPromise])
        .then(([exclusivesListResponse, likesResponse]) => {
          exclusives.value.loaded(exclusivesListResponse);
          likes.value.loaded(likesResponse);
        })
        .catch((error: any) => {
          logger.error('Failed to retrieve exclusives', error);
          exclusives.value.loaded([]);
        });
    };

    const retrievePrograms = async () => {
      let programsListResponse: Program[] = [];
      try {
        programsListResponse = await programRepository.list(clubRepository.getCurrentSlug());
      } catch (error: any) {
        logger.error('Failed to retrieve programs', error);
      } finally {
        programs.value.loaded(programsListResponse.map(program => fromProgram(program)));
      }
    };

    const retrieveMembers = async (): Promise<void> => {
      try {
        const memberListResponse = await memberRepository.getForClub(clubRepository.getCurrentSlug());
        members.value.loaded(memberListResponse.map(member => fromMember(member, member.id === fan.value!.id)));
      } catch (error: any) {
        logger.error('Failed to retrieve members', error);
        members.value.loaded([]);
      }
    };

    const retrievePosts = async () => {
      await postRepository
        .list(clubRepository.getCurrentSlug())
        .then((postList: Post[]) => posts.value.loaded(postList.map(post => fromPost(post, fanLanguage))))
        .catch((error: any) => {
          logger.error('Failed to retrieve posts', error);
          posts.value.loaded([]);
        });
    };

    onMounted(async () => {
      await retrieveAuthentication();

      retrieveAllContent();
    });

    const retrieveAllContent = () => {
      if (club.referralNeeded && !isValidated.value) {
        return;
      }

      retrieveExclusives();

      if (club.isFoundation) {
        retrievePrograms();
        retrievePosts();

        if (club.referralNeeded) {
          retrieveMembers();
        }
      }
    };

    return {
      club,
      carouselExclusives,
      exclusivesWithAuction,
      exclusivesWithoutAuction,
      displayAppContent,
      programs,
      members,
      exclusives,
      hasExclusives,
      hasAuctions,
      hasExclusiveWithoutAuctions,
      hasPrograms,
      hasPosts,
      hasMembers,
      posts,
      isAuthenticated,
      isNotReferred,
      isValidated,
      showTokensDocumentation,
      t,
    };
  },
});

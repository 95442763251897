import { defineComponent, inject } from 'vue';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';

const FAIRPLAYER_CONTACT_EMAIL = import.meta.env.VITE_FAIRPLAYER_MAIL_CONTACT;

export default defineComponent({
  name: 'MailToContact',

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;

    const contactAddress = clubRepository
      .findCurrentClub()
      .map(club => club.contactEmail)
      .orElse(FAIRPLAYER_CONTACT_EMAIL);

    return {
      contactAddress,
    };
  },
});

import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { themeRepositoryKey } from '@/fairplayer/domain/theme/ThemeRepository';

export default defineComponent({
  name: 'ThemeSelector',

  setup() {
    const { t } = useI18n();

    const themeRepository = inject(themeRepositoryKey)!;

    const setTheme = (theme: string) => {
      themeRepository.save(theme);
      themeRepository.set(theme);
    };

    return {
      setTheme,
      t,
    };
  },
});

export const maxCardSize = (width: number, height: number, n: number): number => {
  const canFitSquares = (side: number): boolean => Math.floor(width / side) * Math.floor(height / side) >= n;

  const binarySearch = (low: number, high: number): number => {
    if (low >= high) return low;
    const mid = Math.floor((low + high + 1) / 2);
    return canFitSquares(mid) ? binarySearch(mid, high) : binarySearch(low, mid - 1);
  };

  return binarySearch(1, Math.min(width, height));
};

export const maxSquaresIn = (width: number, height: number, side: number): number => Math.floor(width / side) * Math.floor(height / side);

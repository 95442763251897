include ../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='posts')
  template(v-slot:body)
    template(v-if='!postList.isLoading()')
      .fp-alert.-info(v-if='!postList.value().length' data-test='alert')
        +fp-icon('info', {color: 'info', size: '18'})
        span.fp-text.-paragraph {{ t('posts.alert.emptyPosts') }}
      .fp-responsive-grid.-s-lg.-cols3.-cols1-up-sm.-cols2-up-md(v-if='postList.value().length')
        .fp-responsive-grid--column(v-for='(post, index) in postList.value()' :key='index' :data-test='`post-${index}`' :id='post.slug')
          router-link.fp-link.-no-text-decoration.fp-vertical-spacing.-s-sm(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page')
            .fp-vertical-spacing--line
              .fp-image.-rounded.-expand.-wide.-hoverable
                FairplayerImageVue(:url='post.imageUrl' :alt='post.name' data-test='image' :lazy='index > 9')
            .fp-vertical-spacing--line
              .fp-text.-text-semi-bold.-text-md.-lines-2(data-test='name') {{ post.name }}
            .fp-vertical-spacing--line
              .fp-text.-text-md.-lines-2 {{ post.description.raw }}
            .fp-vertical-spacing--line
              .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}

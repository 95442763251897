import { BookingSlotUi } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots/BookingSlot.ui';
import { BookingSlot } from '@/fairplayer/domain/booking/BookingSlot';
import { LocalDate } from '@/common/domain/LocalDate';

export interface BookingDateUi {
  weekDay: string;
  dayShortMonth: string;
  slots: BookingSlotUi[];
}

export const fromBookingSlots = (slots: BookingSlot[], locale: string): BookingDateUi[] => {
  const groupedByDate: Record<string, BookingSlot[]> = slots.reduce(
    (acc, slot) => {
      const date = slot.startDate.getIsoLocalDate();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(slot);
      return acc;
    },
    {} as Record<string, BookingSlot[]>
  );

  return Object.entries(groupedByDate).map(([date, dateSlots]) => {
    const localDate = LocalDate.of(date);
    const weekDay = localDate.weekday(locale);
    const dayShortMonth = localDate.toDate().toLocaleDateString(locale, { day: 'numeric', month: 'short' });

    const slotsUi: BookingSlotUi[] = dateSlots.map(slot => ({
      id: slot.id,
      startTime: slot.startDate.getIsoTime(),
      dayMonth: localDate.toDate().toLocaleDateString(locale, { day: 'numeric', month: 'long' }),
    }));

    return {
      weekDay,
      dayShortMonth,
      slots: slotsUi,
    };
  });
};

import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { MemberUi } from '@/fairplayer/primary/members-page/Member.ui';

export default defineComponent({
  name: 'BidderImage',

  components: {
    FairplayerImageVue,
  },

  props: {
    bidder: {
      type: Object as PropType<MemberUi>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const dynamicHash = computed(() => hashToColor(props.bidder.id));

    const hashToColor = (hash: string) => {
      const r = (parseInt(hash.slice(0, 2), 36) % 128) + 128;
      const g = (parseInt(hash.slice(2, 4), 36) % 128) + 128;
      const b = (parseInt(hash.slice(4, 6), 36) % 128) + 128;

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };

    return {
      t,
      dynamicHash,
      FairplayerFallbackImageUi,
    };
  },
});

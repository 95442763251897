import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { DonationUi } from '@/fairplayer/primary/profile/donation-list/Donation.ui';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'DonationList',

  props: {
    donationList: {
      type: Array as PropType<DonationUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const hasDonations = computed(() => props.donationList.length > 0);

    const goToDonationPage = async () => await router.push({ name: 'donationPage' });

    return {
      hasDonations,
      goToDonationPage,
      t,
    };
  },
});

import { prefixWithStaticFilesUri } from '@/fairplayer/primary/StaticFilesUriPrefixer';
import { PartnerSlug } from '@/fairplayer/domain/club/partner/PartnerSlug';

export interface PartnerLogoUi {
  url: string;
}

export const partnerLogoFromSlug = (partnerSlug: PartnerSlug): PartnerLogoUi => ({
  url: prefixWithStaticFilesUri(`/partners/${partnerSlug}/logo.png`),
});

import { MessageToCreate } from '@/fairplayer/domain/message/MessageToCreate';

export interface BackendMessageToCreate {
  receiverId: string;
  text: string;
}

export const fromMessageToCreate = (message: MessageToCreate): BackendMessageToCreate => ({
  receiverId: message.receiverId,
  text: message.text,
});

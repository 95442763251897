import { LocalDate } from '@/common/domain/LocalDate';
import { fromTokens } from '@/common/primary/token/Tokens.ui';
import { isExpense, isFiatCentric, Operation } from '@/fairplayer/domain/operation/Operation';
import { OperationType } from '@/fairplayer/domain/operation/OperationType';

export interface OperationUi {
  type: OperationType;
  date: string;
  summary: string;
  pricing: string;
}

export const fromOperation = (operation: Operation): OperationUi => {
  const tokensUi = fromTokens(operation.pricing);
  const summarySign = isExpense(operation) ? '-' : '+';
  const pricing = isFiatCentric(operation) ? tokensUi.totalCost.text : tokensUi.coins.text;
  return {
    type: operation.type,
    summary: `${summarySign} ${tokensUi.coins.text}`,
    pricing,
    date: LocalDate.fromDate(operation.date).toHuman('fr-FR'),
  };
};

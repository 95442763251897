import { Member } from '@/fairplayer/domain/member/Member';
import { Optional } from '@/common/domain/Optional';

export interface BackendMember {
  id: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
}

export const toMember = (backendMember: BackendMember): Member => ({
  id: backendMember.id,
  firstName: backendMember.firstName,
  lastName: backendMember.lastName,
  pictureUrl: Optional.ofUndefinable(backendMember.pictureUrl),
});

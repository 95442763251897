import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { WalletRepository } from '@/fairplayer/domain/fan/wallet/WalletRepository';

export class BackendWalletRepository implements WalletRepository {
  constructor(private backendCaller: BackendCaller) {}

  getForClub(clubSlug: ClubSlug): Promise<Wallet> {
    return this.backendCaller
      .get<BackendPrice>(`api/clubs/${clubSlug}/fans/me/balance`)
      .then(response => new Wallet(toTokens(response.data)));
  }
}

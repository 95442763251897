import { inject, onMounted, onUnmounted, ref } from 'vue';
import { globalWindowKey } from '@/common/domain/Window';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { printerBusKey } from '@/common/domain/printer/PrinterBus';
import { ComponentToPrint } from '@/common/domain/printer/ComponentToPrint';

export default {
  name: 'Printer',

  setup() {
    const globalWindow = inject(globalWindowKey)!;
    const printerBus = inject(printerBusKey)!;

    const isPrinting = ref(false);
    const componentToPrint = ref();
    let unsubscribePrint: Unsubscribe;

    onMounted(async () => {
      unsubscribePrint = printerBus.printed(onPrint);
    });

    onUnmounted(() => {
      unsubscribePrint();
    });

    const onPrint = (printOpened: ComponentToPrint) => {
      componentToPrint.value = printOpened;
      isPrinting.value = true;
    };

    const printComponent = () => {
      globalWindow.print();
    };

    return {
      isPrinting,
      componentToPrint,
      printComponent,
    };
  },
};

import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromGiveaway } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/giveaway-card/CardGiveaway.ui';
import { GiveawayType } from '@/fairplayer/domain/club/GiveawayType';

export default defineComponent({
  name: 'GiveawayCard',

  components: {
    FairplayerButtonVue,
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const giveaways = club.activeGiveaways
      .filter(giveaway => giveaway.type === GiveawayType.GIVEAWAY)
      .map(giveaway => fromGiveaway(giveaway, club));

    return { t, giveaways };
  },
});

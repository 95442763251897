import { GuestWalletVue } from '@/fairplayer/primary/wallet/guest-wallet';
import { OperationsListVue } from '@/fairplayer/primary/wallet/operations-list';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { Loader } from '@/loader/primary/Loader';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted, ref } from 'vue';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { loggerKey } from '@/common/domain/Logger';
import { WalletBalanceVue } from '@/fairplayer/primary/wallet/wallet-balance';

export default {
  name: 'WalletPage',

  components: {
    WalletBalanceVue,
    ClubLogoVue,
    OperationsListVue,
    GuestWalletVue,
    PageVue,
  },

  setup() {
    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;

    const isAuthenticated = ref(Loader.loading<boolean>());

    onMounted(async () => {
      let isAuthenticatedResponse = false;

      try {
        isAuthenticatedResponse = await authentication.isAuthenticated();
      } catch (error: any) {
        logger.error('Failed to authenticate', error);
      } finally {
        isAuthenticated.value.loaded(isAuthenticatedResponse);
      }
    });

    return {
      isAuthenticated,
    };
  },
};

import { Credential } from '@/common/domain/auth/Credential';

export interface SignupFormUi {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const toCredential = (signupForm: SignupFormUi): Credential => ({
  email: signupForm.email,
  password: signupForm.newPassword,
});

export const emptySignupFormUi = () => ({
  email: '',
  newPassword: '',
  confirmNewPassword: '',
});

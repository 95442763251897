import { Partner } from '@/fairplayer/domain/club/partner/Partner';

export interface BackendPartner {
  id: string;
  name: string;
  slug: string;
}

export const toPartner = (backendPartner: BackendPartner): Partner => ({
  id: backendPartner.id,
  name: backendPartner.name,
  slug: backendPartner.slug,
});

import { InjectionKey } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { BusCallback } from '@/common/domain/BusCallback';
import { HighlightedMediaList } from '@/common/domain/highlighted-media/HighlightedMediaList';

export const highlightedMediaBusKey: InjectionKey<HighlightedMediaBus> = Symbol();

export interface HighlightedMediaBus {
  open(medias: HighlightedMediaList): void;
  close(): void;
  opened(callback: BusCallback<HighlightedMediaList>): Unsubscribe;
  closed(callback: BusCallback<void>): Unsubscribe;
}

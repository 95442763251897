include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-sticky-pane(data-test='not-visible-exclusive')
  aside.fp-sticky-pane--aside
    .fp-display
      .fp-display--image
        FairplayerImageVue(:url='exclusive.imageUrl')
        .fp-display--image-mask
          .fp-badge(data-test='hidden-exclusive') {{ t('marketplace.hiddenExclusive') }}
      .fp-display--left-action(@click='goToMarketplace' data-test='go-to-marketplace')
        +fp-icon('arrow-left', {size: '16'})
  .fp-sticky-pane--content
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        ExclusiveDetailHeadingVue(:exclusive='exclusive')
      .fp-vertical-spacing--line
        .fp-alert.-danger
          +fp-icon('close-circle', {color: 'danger', size: '18'})
          span.fp-text(data-test='hidden-exclusive-alert') {{ t('marketplace.hiddenExclusiveDescription') }}

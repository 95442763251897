include ../../../../common/primary/icon/icon.mixin.pug

PageVue(v-if='!exclusive.isLoading() && exclusive.value()')
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-justify-center
        h1.fp-title(data-test='purchase-confirmation.title') {{ t('marketplace.purchaseConfirmation.title') }}
      .fp-vertical-spacing--line.-justify-center(v-if='!wallet.isLoading() && wallet.value()')
        .fp-icon-with-text
          img.fp-illustration.-xs.-token(alt='Token')
          .fp-icon-with-text--text
            .fp-icon-with-text--text-line
              span.fp-text {{ t('availableBalance') }}&nbsp;
              span.fp-text.-color-shade-900.-text-semi-bold {{ wallet.value().balance.coins.text }}
      .fp-vertical-spacing--line
        .fp-illustrated-item
          .fp-image.-lg.-rounded
            FairplayerImageVue(:url='exclusive.value().imageUrl' data-test='purchase-confirmation.exclusive-image' highlightable)
          .fp-illustrated-item--content
            h2.fp-title(data-test='purchase-confirmation.exclusive-name') {{ exclusive.value().name }}
            ExclusivePriceVue(:pricing='exclusive.value().pricing')
      .fp-vertical-spacing--line
        .fp-alert.-info
          i.fp-icon.fp-glyph-info.-color-info.-s18
          span.fp-text {{ t('marketplace.purchaseConfirmation.infoContact') }}
      .fp-vertical-spacing--line.-justify-center
        FairplayerButtonVue(icon='shopping-cart' :loading='isBuyingExclusive' :text='t("marketplace.purchaseConfirmation.confirmButton")' @click='buyExclusive' data-test='purchase-confirmation.button')

import { defineAsyncComponent, markRaw } from 'vue';

const importAsyncModalComponent = (component: any) => markRaw(defineAsyncComponent(component));

export const giveawayParticipationConfirmationModal = () =>
  importAsyncModalComponent(
    () =>
      import(
        '@/fairplayer/primary/giveaway-participation/giveaway-participation-confirmation-modal/GiveawayParticipationConfirmationModal.vue'
      )
  );

export const addBidModal = () =>
  importAsyncModalComponent(() => import('@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/add-bid-modal/AddBidModal.vue'));

export const createReferralModal = () =>
  importAsyncModalComponent(() => import('@/fairplayer/primary/profile/referral-list/create-referral-modal/CreateReferralModal.vue'));

export const clubHomepageHeaderLoginModal = () =>
  importAsyncModalComponent(
    () =>
      import(
        '@/fairplayer/primary/home/club-homepage/club-homepage-header/club-homepage-header-login-modal/ClubHomepageHeaderLoginModal.vue'
      )
  );

export const clubHomepageHeaderNotReferredModal = () =>
  importAsyncModalComponent(
    () =>
      import(
        '@/fairplayer/primary/home/club-homepage/club-homepage-header/club-homepage-header-not-referred-modal/ClubHomepageHeaderNotReferredModal.vue'
      )
  );

import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { HighlightedMediaBus } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { BusCallback } from '@/common/domain/BusCallback';
import { HighlightedMediaList } from '@/common/domain/highlighted-media/HighlightedMediaList';

const OPEN = 'open-media';
const CLOSE = 'close-media';

export class MittHighlightedMediaBus implements HighlightedMediaBus {
  constructor(private emitter: Emitter<any>) {}

  open(medias: HighlightedMediaList): void {
    this.emitter.emit(OPEN, medias);
  }

  close(): void {
    this.emitter.emit(CLOSE);
  }

  opened(callback: BusCallback<HighlightedMediaList>): Unsubscribe {
    const handler: Handler<HighlightedMediaList> = medias => callback(medias);
    this.emitter.on(OPEN, handler);
    return () => this.emitter.off(OPEN, handler);
  }

  closed(callback: BusCallback<void>): Unsubscribe {
    const handler: Handler = () => callback();
    this.emitter.on(CLOSE, handler);
    return () => this.emitter.off(CLOSE, handler);
  }
}

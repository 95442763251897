import { PasswordStrength } from '@/common/domain/login/PasswordStrength';

const strengthUiByKey: Record<PasswordStrength, Omit<PasswordStrengthUi, 'key'>> = {
  [PasswordStrength.TOO_SHORT]: { className: '-danger', accepted: false, fill: 25 },
  [PasswordStrength.WEAK]: { className: '-danger', accepted: false, fill: 50 },
  [PasswordStrength.MEDIUM]: { className: '-warning', accepted: true, fill: 75 },
  [PasswordStrength.STRONG]: { className: '-success', accepted: true, fill: 100 },
};

export interface PasswordStrengthUi {
  key: string;
  accepted: boolean;
  className: string;
  fill: number;
}

export const fromPasswordStrength = (strength: PasswordStrength): PasswordStrengthUi => ({
  key: strength,
  ...strengthUiByKey[strength],
});

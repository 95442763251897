import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { LoginVue } from '@/common/primary/auth/login';

export default {
  name: 'GuestWallet',

  components: {
    ClubLogoVue,
    FairplayerButtonVue,
    LoginVue,
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const club = fromClub(clubRepository.getCurrentClub());

    return { t, club };
  },
};

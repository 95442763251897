import { Optional } from '@/common/domain/Optional';
import { Club } from '@/fairplayer/domain/club/Club';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';
import { BackendClubRepository } from '@/fairplayer/secondary/club/BackendClubRepository';
import { UrlParserClubSlugRepository } from '@/fairplayer/secondary/club/UrlParserClubSlugRepository';
import { NoCurrentClubError } from '@/fairplayer/secondary/club/NoCurrentClubError';

export const SELECTED_CLUB_KEY = 'selected_club_slug';

export class FairplayerClubRepository implements ClubRepository {
  private currentClub: Optional<Club>;

  constructor(
    private backendClubRepository: BackendClubRepository,
    private urlParserClubSlugRepository: UrlParserClubSlugRepository,
    private storage: Storage
  ) {
    this.currentClub = Optional.empty();
  }

  list(): Promise<ClubSummary[]> {
    return this.backendClubRepository.list();
  }

  get(clubSlug: ClubSlug): Promise<Club> {
    return this.backendClubRepository.get(clubSlug);
  }

  retrieveClub(): Promise<Club> {
    return this.backendClubRepository.get(this.getCurrentSlug());
  }

  findSelectedSlug(): Optional<ClubSlug> {
    return Optional.ofUndefinable(this.storage.getItem(SELECTED_CLUB_KEY));
  }

  clearSelectedClub(): void {
    this.storage.removeItem(SELECTED_CLUB_KEY);
  }

  getCurrentSlug(): ClubSlug {
    return this.urlParserClubSlugRepository.findCurrentClubSlug().orElse(this.storage.getItem(SELECTED_CLUB_KEY)!);
  }

  isCustomClubDomain(): boolean {
    return this.urlParserClubSlugRepository.isCustomClubDomain();
  }

  setCurrentClub(club: Club): void {
    this.storage.setItem(SELECTED_CLUB_KEY, club.slug);

    this.currentClub = Optional.of(club);
  }

  findCurrentClub(): Optional<Club> {
    return this.currentClub;
  }

  getCurrentClub(): Club {
    return this.findCurrentClub().orElseThrow(() => new NoCurrentClubError());
  }
}

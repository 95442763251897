include ../../../../common/primary/icon/icon.mixin.pug

.fp-layout
  .fp-layout--body
    PageVue
      template(v-slot:body)
        LoginFormVue(is-redirecting)
      template(v-slot:footer)
        LogoutVue
  .fp-layout--toaster
    ToastsListVue

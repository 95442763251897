import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { FeatureCardVue } from '@/fairplayer/primary/home/club-homepage/feature-card-list/feature-card';

export default defineComponent({
  name: 'FeatureCardList',

  components: { FeatureCardVue },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

import { inject, onMounted } from 'vue';
import { manifestHandlerKey } from '@/fairplayer/primary/ManifestHandler';
import { fanConsentRepositoryKey } from '@/fairplayer/domain/fan/consent/FanConsentRepository';

export default {
  name: 'App',

  setup() {
    const manifestHandler = inject(manifestHandlerKey)!;
    const fairplayerFanConsentRepository = inject(fanConsentRepositoryKey)!;

    onMounted(() => {
      manifestHandler.addDomContentListener();
      fairplayerFanConsentRepository.init();
    });
  },
};

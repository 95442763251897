import { Program } from '@/fairplayer/domain/program/Program';

export interface BackendProgram {
  id: string;
  slug: string;
  imageUrl: string;
  name: string;
  description: string;
}

export const toProgram = (backendProgram: BackendProgram): Program => ({
  slug: backendProgram.slug,
  imageUrl: backendProgram.imageUrl,
  name: backendProgram.name,
  description: backendProgram.description,
});

.fp-vertical-spacing(data-test='posts-list')
  .fp-vertical-spacing--line
    h2.fp-title(data-test='title') {{ t('programs.page.newsFeed') }}
  .fp-vertical-spacing--line
    .fp-responsive-grid.-s-lg.-cols3.-cols1-up-sm.-cols2-up-md
      .fp-responsive-grid--column(v-for='(post, id) in posts' :key='id' :data-test='`post-${id}`')
        router-link.fp-vertical-spacing.-s-sm.fp-link.-no-text-decoration(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page')
          .fp-vertical-spacing--line
            .fp-image.-rounded.-expand.-wide.-hoverable
              FairplayerImageVue(:url='post.imageUrl' data-test='image-post')
          .fp-vertical-spacing--line
            .fp-text.-text-semi-bold(data-test='name') {{ post.name }}
          .fp-vertical-spacing--line
            .fp-text.-lines-1 {{ post.description.raw }}
          .fp-vertical-spacing--line
            .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}

import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendOrder, toOrder } from '@/fairplayer/secondary/fan/order/BackendOrder';
import { Authentication } from '@/common/domain/auth/Authentication';
import { OrderRepository } from '@/fairplayer/domain/fan/order/OrderRepository';
import { Order } from '@/fairplayer/domain/fan/order/Order';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';

export class BackendOrderRepository implements OrderRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async listFor(clubSlug: ClubSlug): Promise<Order[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    return await this.backendCaller
      .get<BackendOrder[]>(`/api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/orders`)
      .then(orders => orders.data.map(toOrder));
  }
}

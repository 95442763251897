import { computed, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PasswordVue } from '@/common/primary/auth/password';
import { passwordStrengthRetrieverKey } from '@/common/domain/login/PasswordStrengthRetriever';
import { fromPasswordStrength } from '@/common/primary/auth/PasswordStrength.ui';
import { NewPasswordUpdated } from '@/common/primary/auth/new-password/NewPasswordUpdated';

export default defineComponent({
  name: 'NewPassword',
  components: { PasswordVue },
  emits: ['updated'],

  setup(props, { emit }) {
    const passwordStrengthRetriever = inject(passwordStrengthRetrieverKey)!;
    const { t } = useI18n();
    const password = ref('');
    const confirmPassword = ref('');

    const strength = computed(() => fromPasswordStrength(passwordStrengthRetriever.retrieve(password.value)));

    const differentPassword = computed(
      () => password.value !== '' && confirmPassword.value !== '' && password.value !== confirmPassword.value
    );

    const trailingOrLeadingSpace = computed(
      () => password.value !== '' && (password.value.startsWith(' ') || password.value.endsWith(' '))
    );

    const validPassword = computed(() => !differentPassword.value && !trailingOrLeadingSpace.value && strength.value.accepted);
    const updated = computed<NewPasswordUpdated>(() => ({ password: password.value, valid: validPassword.value }));

    const updatePassword = (value: string) => {
      password.value = value;
      emit('updated', updated.value);
    };

    const updateConfirmPassword = (value: string) => {
      confirmPassword.value = value;
      emit('updated', updated.value);
    };

    return {
      differentPassword,
      password,
      strength,
      trailingOrLeadingSpace,
      t,
      updatePassword,
      updateConfirmPassword,
    };
  },
});

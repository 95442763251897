import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { FairplayerButtonVue } from '@/common/primary/button';
import { postRepositoryKey } from '@/fairplayer/domain/post/PostRepository';
import { fromPost, PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { useRouter } from 'vue-router';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'Newsfeed',

  components: {
    PageVue,
    FairplayerButtonVue,
    FairplayerImageVue,
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const postRepository = inject(postRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;
    const { t } = useI18n();
    const router = useRouter();

    const postList = ref(Loader.loading<PostUi[]>());

    onMounted(async () => {
      await loadPostList();
      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
    });

    const loadPostList = async (): Promise<void> => {
      try {
        const postListResponse = await postRepository.list(clubRepository.getCurrentSlug());
        postList.value.loaded(postListResponse.map(post => fromPost(post, fanLanguageRepository.getCurrentLanguage())));
      } catch (error: any) {
        logger.error('Failed to retrieve posts', error);
        postList.value.loaded([]);
      }
    };

    return {
      postList,
      t,
    };
  },
});

import { fromWallet, WalletUi } from '@/fairplayer/primary/wallet/Wallet.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { Loader } from '@/loader/primary/Loader';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default {
  name: 'WalletBalance',

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const walletRepository = inject(walletRepositoryKey)!;

    const wallet = ref(Loader.loading<WalletUi>());
    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(async () => {
      await retrieveWallet();
    });

    const retrieveWallet = async () => {
      const currentSlug = clubRepository.getCurrentSlug();
      wallet.value.loaded(fromWallet(await walletRepository.getForClub(currentSlug)));
    };

    return {
      wallet,
      club,
      t,
    };
  },
};

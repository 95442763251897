export default {
  name: 'LogoWithTitle',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup() {
    const patternLibraryUrl = import.meta.env.VITE_PATTERN_LIBRARY_URL;

    return {
      patternLibraryUrl,
    };
  },
};

import { PropType } from 'vue';
export default {
  name: 'LiveAuctionsCard',

  props: {
    cardPxSize: {
      type: Number as PropType<number>,
      required: true,
    },
  },
};

include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line(v-if='giveaways.length')
  .fp-card.-highlighted
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h2.fp-title {{ t('marketplace.giveawayParticipations.earnExclusive') }}
      .fp-vertical-spacing--line
        span.fp-text {{ t('marketplace.giveawayParticipations.operationInProgress') }}
      .fp-vertical-spacing--line(v-for='(giveaway, index) in giveaways' :key='index')
        .fp-horizontal-spacing.-items-center
          .fp-horizontal-spacing--column
            img.fp-illustration.-sm(:src='giveaway.logoUrl')
          .fp-horizontal-spacing--column.-expand
            .fp-vertical-spacing.-s-xs
              .fp-vertical-spacing--line
                span.fp-text.-text-sm {{ t(giveaway.textKey) }}
              .fp-vertical-spacing--line
                span.fp-text.-text-semi-bold.-color-shade-900(:data-test='`giveaway.${index}.sponsor-name`') {{ giveaway.sponsorName }}
          .fp-horizontal-spacing--column
            a.fp-link.-no-text-decoration(:href='giveaway.url' :data-test='`giveaway.${index}.link`')
              FairplayerButtonVue(:text='t("marketplace.giveawayParticipations.participate")' icon='arrow-square-in')

import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { globalWindowKey } from '@/common/domain/Window';
import { useI18n } from 'vue-i18n';
import { fromMillisecondsLeft } from '@/common/primary/Countdown.ui';
import { BidderImageVue } from '@/common/primary/auction/bidder-image';
import { BidderIdentityVue } from '@/common/primary/auction/bidder-identity';
import { AuctionLotUi } from '@/fairplayer/primary/marketplace/AuctionLot.ui';

export default defineComponent({
  name: 'PresentationCardAuction',

  components: {
    BidderImageVue,
    BidderIdentityVue,
  },

  props: {
    auctionLot: {
      type: Object as PropType<AuctionLotUi>,
      required: true,
    },
  },

  setup(props) {
    const globalWindow = inject(globalWindowKey)!;
    const { t } = useI18n();

    const intervalId = ref(0);
    const now = ref(Date.now());

    const millisecondsBeforeStartDate = computed(() => props.auctionLot.auction.startDate.getTime() - now.value);
    const countdownBeforeStartDate = computed(() => fromMillisecondsLeft(millisecondsBeforeStartDate.value));

    const updateNow = () => {
      now.value = Date.now();
    };

    onMounted(() => {
      intervalId.value = globalWindow.setInterval(updateNow, 1000);
    });

    onUnmounted(() => {
      globalWindow.clearInterval(intervalId.value);
    });

    return {
      t,
      millisecondsBeforeStartDate,
      countdownBeforeStartDate,
    };
  },
});

import { useI18n } from 'vue-i18n';
import { defineComponent } from 'vue';
import { PageVue } from '@/fairplayer/primary/page';
import { FairplayerButtonVue } from '@/common/primary/button';

export default defineComponent({
  name: 'OneTimeDonationPaymentConfirmed',

  components: {
    PageVue,
    FairplayerButtonVue,
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { BookingSlot } from '@/fairplayer/domain/booking/BookingSlot';
import { BookingSlotId } from '@/fairplayer/domain/booking/BookingSlotId';

export const bookingSlotRepositoryKey: InjectionKey<BookingSlotRepository> = Symbol();

export interface BookingSlotRepository {
  list(clubSlug: ClubSlug): Promise<BookingSlot[]>;
  book(clubSlug: ClubSlug, bookingSlotId: BookingSlotId): Promise<void>;
}

import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveAuctionBidVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-bid';
import { FairplayerButtonVue } from '@/common/primary/button';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { Loader } from '@/loader/primary/Loader';
import { LoginVue } from '@/common/primary/auth/login';
import { ExclusiveAuctionCountdownVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-countdown';

export default defineComponent({
  name: 'ExclusiveAuction',

  components: {
    ExclusiveAuctionBidVue,
    ExclusiveAuctionCountdownVue,
    FairplayerButtonVue,
    LoginVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  emits: ['add-bid'],

  setup(props, { emit }) {
    const authentication = inject(authenticationKey)!;
    const { t } = useI18n();

    const isAuthenticated = ref(Loader.loading<boolean>());

    const addBid = () => emit('add-bid');

    onMounted(async () => {
      isAuthenticated.value.loaded(await authentication.isAuthenticated());
    });

    return {
      t,
      addBid,
      isAuthenticated,
    };
  },
});

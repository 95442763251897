import { computed, defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { FairplayerButtonVue } from '@/common/primary/button';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { LoginVue } from '@/common/primary/auth/login';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'ExclusivePageFooter',

  components: {
    ExclusivePriceVue,
    FairplayerButtonVue,
    LoginVue,
  },

  props: {
    exclusiveUi: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },

    isAuthenticated: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    isBuying: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    auctionPaymentDue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  emits: ['buy'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const buyButtonDisabled = computed(
      () =>
        props.exclusiveUi.isOutOfStock ||
        props.exclusiveUi.isUpcoming ||
        !props.exclusiveUi.visible ||
        (props.exclusiveUi.auctionLot && props.exclusiveUi.auctionLot!.auction.status !== AuctionStatus.ACTIVE && !props.auctionPaymentDue)
    );

    const buyButtonIcon = computed(() => {
      if (props.auctionPaymentDue) {
        return 'euro';
      }

      if (props.exclusiveUi.auctionLot) {
        return 'plus';
      }

      if (club.isMarketplace) {
        return 'shopping-cart';
      }

      return 'hand-heart';
    });

    const buyButtonKey = computed(() => {
      if (props.auctionPaymentDue) {
        return 'paymentDue';
      }

      if (props.exclusiveUi.auctionLot) {
        return 'addBid';
      }

      if (club.isMarketplace) {
        return 'buy';
      }

      return 'support';
    });

    const buy = () => {
      emit('buy');
    };

    return {
      t,
      buyButtonDisabled,
      buyButtonIcon,
      buyButtonKey,
      buy,
    };
  },
});

import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusiveOrder } from '@/fairplayer/domain/fan/order/exclusive-order/ExclusiveOrder';

export interface BackendExclusiveOrder {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  price: BackendPrice;
  category: ExclusiveCategory;
}

export const toExclusiveOrder = (backendExclusiveOrder: BackendExclusiveOrder): ExclusiveOrder => ({
  id: backendExclusiveOrder.id,
  name: backendExclusiveOrder.name,
  description: backendExclusiveOrder.description,
  imageUrl: backendExclusiveOrder.imageUrl,
  pricing: toTokens(backendExclusiveOrder.price),
  category: backendExclusiveOrder.category,
});

import { defineComponent, inject, PropType } from 'vue';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { TokensUi } from '@/common/primary/token/Tokens.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ExclusivePrice',

  props: {
    pricing: {
      type: Object as PropType<TokensUi>,
      required: true,
    },
    lg: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    xl: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    vertical: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());
    const hideCoinsPrice = !club.coinsEnabled;
    const hideFiatPrice = !club.fiatAccepted;

    return { hideCoinsPrice, hideFiatPrice, t };
  },
});

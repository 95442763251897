import { Club } from '@/fairplayer/domain/club/Club';
import { Giveaway } from '@/fairplayer/domain/club/Giveaway';
import { partnerLogoFromSlug } from '@/fairplayer/primary/club/PartnerLogo.ui';

export interface CardGiveawayUi {
  url: string;
  logoUrl: string;
  textKey: string;
  sponsorName: string;
}

export const fromGiveaway = (giveaway: Giveaway, club: Club): CardGiveawayUi => ({
  logoUrl: giveaway.partner.map(partner => partnerLogoFromSlug(partner.slug).url).orElse(club.logoUrl),
  textKey: giveaway.partner.isPresent() ? 'homepage.sponsoredBy' : 'homepage.offeredBy',
  sponsorName: giveaway.partner.map(partner => partner.name).orElse(club.name),
  url: giveaway.url,
});

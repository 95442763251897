import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';
import { BackendCoins, toCoins } from '@/common/secondary/token/BackendCoins';
import { Tokens } from '@/common/domain/token/Tokens';

export interface BackendPrice {
  coins: BackendCoins;
  totalCost: BackendFiat;
  coinCost: BackendFiat;
}

export const toTokens = (backendPrice: BackendPrice): Tokens =>
  Tokens.of(toCoins(backendPrice.coins)).withTokenCost(toFiat(backendPrice.coinCost));

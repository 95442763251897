import { Url } from '@/common/domain/Url';
import { FanToUpdate } from '@/fairplayer/domain/fan/FanToUpdate';

export interface BackendFanToUpdate {
  pictureUrl: Url;
}

export const toBackendFanToUpdate = (fanToUpdate: FanToUpdate): BackendFanToUpdate => ({
  pictureUrl: fanToUpdate.pictureUrl,
});

import { Fiat } from '@/common/domain/token/Fiat';
import { Coins } from '@/common/domain/token/Coins';

const roundToFiveDecimals = (value: number) => Math.round((value + Number.EPSILON) * 100000) / 100000;

export class Tokens {
  private constructor(
    public readonly coins: Coins,
    public readonly totalCost: Fiat,
    public readonly tokenCost: Fiat
  ) {}

  static of(coins: Coins) {
    return new Tokens.TokensBuilder(coins);
  }

  static TokensBuilder = class {
    constructor(private coins: Coins) {}

    withTokenCost(tokenCost: Fiat): Tokens {
      const totalCost = { value: this.coins.amount * tokenCost.value, currency: tokenCost.currency };

      return this.buildTokens(this.coins, totalCost, tokenCost);
    }

    withTotalCost(totalCost: Fiat) {
      const tokenCost = { value: totalCost.value / this.coins.amount, currency: totalCost.currency };

      return this.buildTokens(this.coins, totalCost, tokenCost);
    }

    private buildTokens(coins: Coins, totalCost: Fiat, tokenCost: Fiat) {
      return new Tokens(
        new Coins(roundToFiveDecimals(coins.amount), coins.ticker),
        { value: roundToFiveDecimals(totalCost.value), currency: totalCost.currency },
        {
          value: roundToFiveDecimals(tokenCost.value),
          currency: tokenCost.currency,
        }
      );
    }
  };
}

import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import {
  GiveawayAnsweredQuestionUi,
  toGiveawayAnsweredQuestion,
} from '@/fairplayer/primary/giveaway-participation/giveaway-participation-quiz/GiveawayAnsweredQuestion.ui';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { Optional } from '@/common/domain/Optional';
import { giveawayParticipationRepositoryKey } from '@/fairplayer/domain/giveaway/GiveawayParticipationRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { giveawayParticipationConfirmationModal } from '@/common/primary/modal/Modals';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { useRoute } from 'vue-router';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { GiveawayParticipationQuizVue } from '@/fairplayer/primary/giveaway-participation/giveaway-participation-quiz';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { loggerKey } from '@/common/domain/Logger';
import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';

export default defineComponent({
  name: 'GiveawayParticipationForm',

  components: { FairplayerButtonVue, GiveawayParticipationQuizVue },

  setup() {
    const { t } = useI18n();

    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const giveawayParticipationRepository = inject(giveawayParticipationRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;
    const route = useRoute();

    const emailRegistered = ref(false);
    const firstNameInput = ref('');
    const lastNameInput = ref('');
    const emailInput = ref('');
    const fanAlreadyParticipated = ref(false);
    const club = fromClub(clubRepository.getCurrentClub());

    const isActive = computed(() => club.activeGiveaways.filter(giveaway => giveaway.partner?.slug === partnerSlug.value).length > 0);
    const activeGiveaway = computed(() => club.activeGiveaways.filter(giveaway => giveaway.partner?.slug === partnerSlug.value)[0]);
    const formDisabled = computed(() => emailRegistered.value || fanAlreadyParticipated.value);
    const answeredQuestions = new Map<string, GiveawayAnsweredQuestionUi>();
    const partnerSlug = computed(() => route.query['partner'] as string);
    const partner = computed(() => club.partners.find(p => p.slug === partnerSlug.value));

    const answered = (answeredQuestion: GiveawayAnsweredQuestionUi) => {
      answeredQuestions.set(answeredQuestion.questionId, answeredQuestion);
    };

    const retrieveFan = async () => {
      const isAuthenticated = await authentication.isAuthenticated();

      if (isAuthenticated) {
        const fan = await authentication.authenticatedFan();
        return Promise.all([
          fanRepository.getForClub(clubRepository.getCurrentClub()),
          giveawayParticipationRepository.listFor(club.slug, fan.username),
        ])
          .then(([fan, participations]) => prefillFanIdentity(fan, participations))
          .catch((error: any) => {
            logger.error('Failed to retrieve fan', error);
          });
      }
    };

    const prefillFanIdentity = (fan: Fan, participations: GiveawayParticipation[]) => {
      if (participations.map(gp => gp.giveawayId).includes(activeGiveaway.value?.id)) {
        registeredFan();
      } else {
        firstNameInput.value = fan.identity.firstName;
        lastNameInput.value = fan.identity.lastName;
        emailInput.value = fan.identity.email;
      }
    };

    const registeredFan = async () => {
      fanAlreadyParticipated.value = true;
      openGiveawayParticipationConfirmationModal();
    };

    const registerParticipation = async () =>
      await giveawayParticipationRepository
        .register({
          clubSlug: clubRepository.getCurrentSlug(),
          firstName: firstNameInput.value,
          lastName: lastNameInput.value,
          email: emailInput.value,
          answeredQuestions: Array.from(answeredQuestions.values()).map(toGiveawayAnsweredQuestion),
          partnerSlug: Optional.ofUndefinable(partner.value?.slug),
        })
        .then(() => {
          emailRegistered.value = true;
          openGiveawayParticipationConfirmationModal();
        });

    const openGiveawayParticipationConfirmationModal = async () => {
      const modal = giveawayParticipationConfirmationModal();
      modalBus.open({ component: modal, closableByUser: false });
    };

    onMounted(() => retrieveFan());

    return {
      answered,
      registerParticipation,
      club,
      isActive,
      activeGiveaway,
      firstNameInput,
      lastNameInput,
      emailInput,
      formDisabled,
      t,
      staticFilesUri,
    };
  },
});

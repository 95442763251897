import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendMember, toMember } from '@/fairplayer/secondary/member/BackendMember';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { MemberRepository } from '@/fairplayer/domain/member/MemberRepository';
import { Member } from '@/fairplayer/domain/member/Member';

export class BackendMemberRepository implements MemberRepository {
  constructor(private backendCaller: BackendCaller) {}

  async getForClub(clubSlug: ClubSlug): Promise<Member[]> {
    return this.backendCaller.get<BackendMember[]>(`api/clubs/${clubSlug}/members`).then(members => members.data.map(toMember));
  }
}

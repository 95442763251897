import { MailToContactVue } from '@/fairplayer/primary/email';
import { PageVue } from '@/fairplayer/primary/page';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';

export default {
  name: 'PurchaseConfirmed',

  components: { MailToContactVue, PageVue, FairplayerButtonVue },

  setup() {
    const { t } = useI18n();

    return { t };
  },
};

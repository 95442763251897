import { defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Password',

  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
    placeholderKey: {
      type: String as PropType<string>,
      required: true,
    },
    autocomplete: {
      type: String as PropType<string>,
      required: true,
    },
    alert: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['updated'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const value = ref('');
    const showValue = ref(false);

    const onUpdate = (event: Event) => {
      const updatedValue = (event.target as HTMLInputElement).value;

      emit('updated', { value: updatedValue });
    };

    const toggleValue = () => {
      showValue.value = !showValue.value;
    };

    return {
      value,
      onUpdate,
      showValue,
      toggleValue,
      t,
    };
  },
});

import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { programRepositoryKey } from '@/fairplayer/domain/program/ProgramRepository';
import { fromProgram, ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { useRoute, useRouter } from 'vue-router';
import { programNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { NotFoundVue } from '@/common/primary/not-found';
import { OtherProgramsVue } from '@/fairplayer/primary/programs/program-page/other-programs';
import { Optional } from '@/common/domain/Optional';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { fromPost, PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { postRepositoryKey } from '@/fairplayer/domain/post/PostRepository';
import { ProgramPostsListVue } from '@/fairplayer/primary/programs/program-page/program-posts-list';

export default defineComponent({
  name: 'ProgramPage',

  components: {
    PageVue,
    NotFoundVue,
    OtherProgramsVue,
    ProgramPostsListVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;
    const postRepository = inject(postRepositoryKey)!;

    const programUi = ref(Loader.loading<ProgramUi>());
    const programs = ref(Loader.loading<ProgramUi[]>());
    const otherPrograms = computed(() => programs.value?.value().filter(program => program.slug !== currentProgramSlug.value));
    const displayProgramNotFound = ref<boolean>(false);
    const postList = ref(Loader.loading<PostUi[]>());

    const currentProgramSlug = computed(() => route.params.programSlug as string);

    const fanLanguage = fanLanguageRepository.getCurrentLanguage();

    onMounted(async () => {
      await loadProgram();
      await loadPrograms();
      await loadPostList();
    });

    const loadProgram = async (): Promise<void> => {
      try {
        programUi.value.loaded(
          (await programRepository.getBySlug(clubRepository.getCurrentSlug(), currentProgramSlug.value))
            .map(program => fromProgram(program))
            .orElseThrow()
        );
      } catch (error: any) {
        logger.error('Failed to retrieve program', error);
        displayProgramNotFound.value = true;
      }
    };

    const loadPrograms = async (): Promise<void> => {
      try {
        const programListResponse = await programRepository.list(clubRepository.getCurrentSlug());
        programs.value.loaded(programListResponse.map(program => fromProgram(program)));
      } catch (error: any) {
        logger.error('Failed to retrieve programs', error);
        programs.value.loaded([]);
      }
    };

    const loadPostList = async (): Promise<void> => {
      try {
        const postListResponse = await postRepository.getByProgram(clubRepository.getCurrentSlug(), currentProgramSlug.value);
        postList.value.loaded(postListResponse.map(post => fromPost(post, fanLanguage)));
      } catch {
        postList.value.loaded([]);
      }
    };

    const goToPrograms = async (): Promise<void> => {
      await router.push({ name: 'programs' });
    };

    watch(
      () => [currentProgramSlug.value],
      () => {
        Optional.ofEmpty(currentProgramSlug.value).ifPresent(async () => {
          await loadProgram();
          await loadPostList();
          windowScroller.scrollToTop();
        });
      }
    );

    return {
      displayProgramNotFound,
      goToPrograms,
      programUi,
      programs,
      otherPrograms,
      programNotFoundUi,
      postList,
      t,
    };
  },
});

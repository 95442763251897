import { Optional } from '@/common/domain/Optional';

export enum Currency {
  EURO = '€',
}

export const fromSymbol = (symbol: string): Currency => {
  return Optional.ofUndefinable(Object.entries(Currency).find(([, value]) => symbol === value)).orElseThrow(
    () => new Error(`Unknown Currency symbol "${symbol}"`)
  )[1];
};

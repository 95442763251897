import { BookingSlot } from '@/fairplayer/domain/booking/BookingSlot';
import { LocalDateTime } from '@/common/domain/LocalDateTime';

export interface BackendBookingSlot {
  id: string;
  startDate: string;
}

export const toBookingSlot = (bookingSlot: BackendBookingSlot): BookingSlot => ({
  id: bookingSlot.id,
  startDate: LocalDateTime.of(bookingSlot.startDate),
});

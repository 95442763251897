import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Loader } from '@/loader/primary/Loader';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import {
  BookingDateUi,
  fromBookingSlots,
} from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots/BookingDate.ui';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { bookingSlotRepositoryKey } from '@/fairplayer/domain/booking/BookingSlotRepository';
import { bookingSlotConfirmationBottomModal } from '@/common/primary/bottom-modal/BottomModals';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { BookingSlotConfirmationModalOptions } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots/booking-slot-confirmation-modal/BookingSlotConfirmationModalOptions';
import { BookingSlotUi } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots/BookingSlot.ui';

export default defineComponent({
  name: 'BookingSlots',

  setup() {
    const { t } = useI18n();

    const bookingClubRepository = inject(bookingSlotRepositoryKey)!;
    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;

    const bookingDates = ref(Loader.loading<BookingDateUi[]>());

    const openBookingSlotConfirmation = (slot: BookingSlotUi) => {
      const modal = bookingSlotConfirmationBottomModal();
      bottomModalBus.open<BookingSlotConfirmationModalOptions>({
        component: modal,
        icon: 'calendar-check',
        titleKey: 'bookingSlots.bookSlot',
        options: {
          slot,
          book,
        },
      });
    };

    const book = (id: string) =>
      bookingClubRepository.book(clubRepository.getCurrentSlug(), id).then(async () => {
        bottomModalBus.close();
        await listBookingSlots();
      });

    const listBookingSlots = () =>
      bookingClubRepository
        .list(clubRepository.getCurrentSlug())
        .then(slots => bookingDates.value.loaded(fromBookingSlots(slots, fanLanguageRepository.getCurrentLanguage())));

    onMounted(async () => await listBookingSlots());

    return {
      t,
      book,
      openBookingSlotConfirmation,
      bookingDates,
    };
  },
});

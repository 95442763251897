PageVue
  template(v-slot:body)
    .fp-vertical-spacing.-s-xl
      .fp-vertical-spacing--line.-justify-center
        ClubLogoVue
      template(v-if='!isAuthenticated.isLoading() && !isAuthenticated.value()')
        GuestWalletVue
      template(v-if='!isAuthenticated.isLoading() && isAuthenticated.value()')
        .fp-vertical-spacing--line
          WalletBalanceVue
        .fp-vertical-spacing--line
          OperationsListVue

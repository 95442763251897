.fp-vertical-spacing(data-test='exclusives-list-filter')
  .fp-vertical-spacing--line
    .fp-vertical-spacing.-s-sm
      .fp-vertical-spacing--line
        span.fp-text.-text-semi-bold {{ t('marketplace.categories.sort.price.title') }}
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-radio#sort-price-asc(data-test='sort-price-asc' type='radio' name='sort' v-model='sortByOrder' value='price:asc')
          .fp-checkable-element--label
            label(for='sort-price-asc') {{ t('marketplace.categories.sort.price.sortByPriceAsc') }}
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-radio#sort-price-desc(data-test='sort-price-desc' type='radio', name='sort' v-model='sortByOrder' value='price:desc')
          .fp-checkable-element--label
            label(for='sort-price-desc') {{ t('marketplace.categories.sort.price.sortByPriceDesc') }}
  .fp-vertical-spacing--line
    .fp-vertical-spacing.-s-sm
      .fp-vertical-spacing--line
        span.fp-text.-text-semi-bold {{ t('marketplace.categories.title') }}
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-checkbox#experience-checkbox(data-test='experience-checkbox' type='checkbox' v-model='isExperienceChecked')
          .fp-checkable-element--label
            label(for='experience-checkbox') {{ t('marketplace.categories.experience') }}
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-checkbox#collectible-checkbox(data-test='collectible-checkbox' type='checkbox' v-model='isCollectibleChecked')
          .fp-checkable-element--label
            label(for='collectible-checkbox') {{ t('marketplace.categories.collectible') }}
  .fp-vertical-spacing--line.-justify-center
    .fp-horizontal-spacing.-wrap
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t("marketplace.cancel")' icon='close' secondary @click='closeFilters' data-test='close-filters')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(data-test='update-filters' :text='t("marketplace.confirm")' icon='check' @click='updateFilters')

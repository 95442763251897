import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { MediaType } from '@/common/domain/MediaType';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';

export default defineComponent({
  name: 'FairplayerImage',

  props: {
    highlightable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    contain: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    lazy: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    url: {
      type: String as PropType<string>,
      default: '',
      required: false,
    },
    fallbackImage: {
      type: String as PropType<FairplayerFallbackImageUi>,
      default: FairplayerFallbackImageUi.EXCLUSIVE,
      required: false,
    },
  },

  emits: ['load'],

  setup(props, { emit }) {
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;

    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const imageNotFound = ref(!props.url);

    const fallbackImageName = computed((): string => {
      if (props.fallbackImage === FairplayerFallbackImageUi.FAN) {
        return 'fan.jpg';
      }

      return 'exclusive.jpg';
    });

    const loading = computed(() => {
      if (props.lazy) {
        return 'lazy';
      }

      return 'eager';
    });

    const onNotFoundImage = () => {
      imageNotFound.value = true;
    };

    const onClick = (url: string) => {
      if (props.highlightable) {
        highlightedMediaBus.open({ activeIndex: 0, medias: [{ url, type: MediaType.IMAGE }] });
      }
    };

    const onLoad = () => {
      emit('load');
    };

    watch(
      () => [props.url],
      () => {
        imageNotFound.value = !props.url;
      }
    );

    return {
      loading,
      fallbackImageName,
      imageNotFound,
      onNotFoundImage,
      onClick,
      staticFilesUri,
      onLoad,
    };
  },
});

import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { Post } from '@/fairplayer/domain/post/Post';
import { PostSlug } from '@/fairplayer/domain/post/PostSlug';
import { Optional } from '@/common/domain/Optional';
import { ProgramSlug } from '@/fairplayer/domain/program/ProgramSlug';

export const postRepositoryKey: InjectionKey<PostRepository> = Symbol();

export interface PostRepository {
  list(clubSlug: ClubSlug): Promise<Post[]>;
  getBySlug(clubSlug: ClubSlug, postSlug: PostSlug): Promise<Optional<Post>>;
  getByProgram(clubSlug: ClubSlug, programSlug: ProgramSlug): Promise<Post[]>;
}

import { authenticationKey } from '@/common/domain/auth/Authentication';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { LogoutVue } from '@/common/primary/auth/logout';
import { LoginFormVue } from '@/common/primary/auth/login-form';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { useRouter } from 'vue-router';
import { CLUB_HOMEPAGE_ROUTE_NAME } from '@/router/routes';
import { Club } from '@/fairplayer/domain/club/Club';

export default {
  name: 'LoginRedirectCallback',

  components: { PageVue, LoginFormVue, ToastsListVue, LogoutVue },

  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const loginContextRepository = inject(loginContextRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const redirectToClubHomePage = async () => {
      await router.push({ name: CLUB_HOMEPAGE_ROUTE_NAME, params: { slug: clubRepository.getCurrentSlug() } });
    };

    const redirectToKyc = async () => {
      await router.push({ name: 'kyc', params: { slug: clubRepository.getCurrentSlug() } });
    };

    const redirectFan = async (club: Club, fan: Fan): Promise<void> => {
      if (club.referralNeeded && fan.kycStatus === KycStatus.NOT_REFERRED) {
        return redirectToClubHomePage();
      }

      if (fan.kycStatus !== KycStatus.VALIDATED) {
        return redirectToKyc();
      }

      const loginContext = loginContextRepository.retrieveLoginContext();
      if (loginContext.redirectUrl) {
        return pageRedirecter.navigateTo(loginContext.redirectUrl);
      }

      return redirectToClubHomePage();
    };

    onMounted(async () => {
      Promise.all([clubRepository.retrieveClub(), authentication.authenticatedFan()]).then(async ([club, fanIdentity]) => {
        const fan = await fanRepository.registerFan({ clubSlug: club.slug, pictureUrl: fanIdentity.pictureUrl });
        await redirectFan(club, fan);
      });
    });

    return {
      t,
    };
  },
};

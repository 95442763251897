import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { MemberCardVue } from '@/fairplayer/primary/members-page/members-list/member-card';
import { MemberUi } from '@/fairplayer/primary/members-page/Member.ui';
import { InterlocutorsListVue } from '@/fairplayer/primary/members-page/members-list/interlocutors-list';
import { InterlocutorMessagesUi } from '@/fairplayer/primary/InterlocutorMessages.ui';

export default defineComponent({
  name: 'MembersList',

  components: {
    MemberCardVue,
    InterlocutorsListVue,
  },

  props: {
    membersList: {
      type: Array as PropType<MemberUi[]>,
      required: true,
    },
    interlocutorsMessages: {
      type: Array as PropType<InterlocutorMessagesUi[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

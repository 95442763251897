import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { OrderCost } from '@/fairplayer/domain/fan/order/OrderCost';

export interface OrderCostUi {
  originalCost: TokensUi;
  discount: TokensUi;
  finalCost: TokensUi;
  finalCoins: TokensUi;
}

export const fromOrderCost = (orderCost: OrderCost): OrderCostUi => ({
  originalCost: fromTokens(orderCost.originalCost),
  discount: fromTokens(orderCost.discount),
  finalCost: fromTokens(orderCost.finalCost),
  finalCoins: fromTokens(orderCost.finalCoins),
});

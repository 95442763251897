import { Partner } from '@/fairplayer/domain/club/partner/Partner';
import { partnerLogoFromSlug, PartnerLogoUi } from '@/fairplayer/primary/club/PartnerLogo.ui';

export interface PartnerUi {
  id: string;
  name: string;
  slug: string;
  logo: PartnerLogoUi;
}

export const fromPartner = (partner: Partner): PartnerUi => ({
  id: partner.id,
  name: partner.name,
  slug: partner.slug,
  logo: partnerLogoFromSlug(partner.slug),
});

import { BidUi, fromBid } from '@/fairplayer/primary/marketplace/Bid.ui';
import { FanId } from '@/common/domain/FanId';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { AuctionUi, fromAuction } from '@/fairplayer/primary/marketplace/Auction.ui';
import { AuctionLot } from '@/fairplayer/domain/exclusive/AuctionLot';

export interface AuctionLotUi {
  startingPrice: TokensUi;
  currentPrice: TokensUi;
  auction: AuctionUi;
  number?: number;
  bids: BidUi[];
}

export const fromAuctionLot = (auctionLot: AuctionLot, fanId?: FanId): AuctionLotUi => ({
  startingPrice: fromTokens(auctionLot.startingPrice),
  currentPrice: fromTokens(auctionLot.currentPrice),
  auction: fromAuction(auctionLot.auction),
  number: auctionLot.number.orElseUndefined(),
  bids: auctionLot.bids.map(bid => fromBid(bid, bid.bidder.id === fanId, auctionLot.auction.anonymous)),
});

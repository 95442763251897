import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { defineComponent, inject, onMounted, PropType, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { LoginVue } from '@/common/primary/auth/login';
import { clubHomepageHeaderLoginModal, clubHomepageHeaderNotReferredModal } from '@/common/primary/modal/Modals';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { BannerImageVue } from '@/common/primary/banner-image';
import { LogoutVue } from '@/common/primary/auth/logout';

export default defineComponent({
  name: 'ClubHomepageHeader',

  components: {
    ClubLogoVue,
    BannerImageVue,
    FairplayerButtonVue,
    LoginVue,
    LogoutVue,
  },

  props: {
    displayAppContent: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    authenticated: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    notReferred: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hasExclusives: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hasPrograms: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(() => {
      if (club.vip && !props.authenticated) {
        openClubHomepageHeaderLoginModal();
      }
    });

    const openClubHomepageHeaderLoginModal = () => {
      const modal = clubHomepageHeaderLoginModal();
      modalBus.open({
        component: modal,
        closableByUser: false,
      });
    };

    const openClubHomepageHeaderNotReferredModal = () => {
      const modal = clubHomepageHeaderNotReferredModal();
      modalBus.open({
        component: modal,
        closableByUser: false,
      });
    };

    watch(
      () => [props.notReferred],
      () => {
        if (club.vip && props.notReferred) {
          openClubHomepageHeaderNotReferredModal();
        }
      }
    );

    return {
      club,
      t,
    };
  },
});

import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { MessageRepository } from '@/fairplayer/domain/message/MessageRepository';
import { Message } from '@/fairplayer/domain/message/Message';
import { BackendMessage, toMessage } from '@/fairplayer/secondary/message/BackendMessage';
import { Authentication } from '@/common/domain/auth/Authentication';
import { MessageToCreate } from '@/fairplayer/domain/message/MessageToCreate';
import { fromMessageToCreate } from '@/fairplayer/secondary/message/BackendMessageToCreate';
import { BackendInterlocutorMessages, toInterlocutorMessages } from '@/fairplayer/secondary/message/BackendInterlocutorMessages';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';
import { fromSenderId } from '@/fairplayer/secondary/message/BackendConversationToRead';
import { FanId } from '@/common/domain/FanId';

export class BackendMessageRepository implements MessageRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async getForClub(clubSlug: ClubSlug): Promise<InterlocutorMessages[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    return this.backendCaller
      .get<BackendInterlocutorMessages[]>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/messages`)
      .then(interlocutorMessages => interlocutorMessages.data.map(toInterlocutorMessages));
  }

  async sendForClub(clubSlug: ClubSlug, message: MessageToCreate): Promise<Message> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    return this.backendCaller
      .post<BackendMessage>(
        `api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/messages`,
        fromMessageToCreate(message)
      )
      .then(message => toMessage(message.data));
  }

  async readConversation(clubSlug: ClubSlug, senderId: FanId): Promise<void> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    await this.backendCaller.post<void>(
      `api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/conversation-reads`,
      fromSenderId(senderId)
    );
  }
}

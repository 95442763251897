import { Message } from '@/fairplayer/domain/message/Message';

export interface MessageUi {
  id: string;
  received: boolean;
  text: string;
  date: Date;
}

export const fromMessage = (message: Message, received: boolean): MessageUi => ({
  id: message.id,
  received,
  text: message.text,
  date: message.date,
});

include ../../../common/primary/icon/icon.mixin.pug

.fp-presentation-card
  .fp-presentation-card--image
    FairplayerImageVue(:url='exclusive.imageUrl')
    PresentationCardAuctionVue(v-if='exclusive.auctionLot' :auctionLot='exclusive.auctionLot')
    template(v-if='showLike')
      .fp-presentation-card--image--right-action(v-if='!localLiked' @click.stop='like' data-test='like')
        +fp-icon('heart', { size: 16})
      .fp-presentation-card--image--right-action(v-else @click.stop='unlike' data-test='unlike')
        +fp-icon('heart-filled', { size: 16, pop: true, color: 'danger' })
  .fp-presentation-card--title(:title='exclusive.name') {{ exclusive.name }}
  .fp-presentation-card--line
    .fp-horizontal-spacing.-s-sm(v-if='exclusive.auctionLot')
      .fp-horizontal-spacing--column.-expand
        ExclusivePriceVue(:pricing='exclusive.auctionLot.currentPrice' data-test='auction-pricing')
      .fp-horizontal-spacing--column(v-if='exclusive.auctionLot?.number')
        span.fp-tag(data-test='lot-number')
          span.fp-tag--text {{ t('marketplace.auction.lot') }} {{ exclusive.auctionLot.number }}
    ExclusivePriceVue(v-else :pricing='exclusive.pricing' data-test='pricing')
  .fp-presentation-card--mask(v-if='maskText')
    .fp-badge(data-test='mask-text') {{ maskText }}

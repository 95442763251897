import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { Authentication } from '@/common/domain/auth/Authentication';
import { FanLanguageRepository } from '@/common/domain/FanLanguageRepository';

const addTokenBearerTo =
  (config: Partial<AxiosRequestConfig>): ((token: string) => Partial<AxiosRequestConfig>) =>
  token => ({
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  });

export class BackendCaller {
  constructor(
    private axiosInstance: AxiosInstance,
    private alertBus: AlertBus,
    private authentication: Authentication,
    private fanLanguageRepository: FanLanguageRepository
  ) {}

  async get<T>(uri: string): Promise<AxiosResponse<T>> {
    return await this.axiosInstance.get(uri, await this.configWithHeaders()).catch(error => this.throwError(error));
  }

  async post<T>(uri: string, data?: any): Promise<AxiosResponse<T>> {
    return await this.axiosInstance.post(uri, data, await this.configWithHeaders()).catch(error => this.throwError(error));
  }

  async patch<T>(uri: string, data?: any): Promise<AxiosResponse<T>> {
    return await this.axiosInstance.patch(uri, data, await this.configWithHeaders()).catch(error => this.throwError(error));
  }

  async put<T>(uri: string, data?: any): Promise<AxiosResponse<T>> {
    return await this.axiosInstance.put(uri, data, await this.configWithHeaders()).catch(error => this.throwError(error));
  }

  private throwError(error: any): never {
    this.alertBus.alert({ message: 'errors.backend.unknown', type: 'danger' });
    throw new Error(error);
  }

  private async configWithHeaders(): Promise<Partial<AxiosRequestConfig>> {
    const config: Partial<AxiosRequestConfig> = {
      headers: { 'Accept-Language': this.fanLanguageRepository.getCurrentLanguage() },
    };

    const token = await this.authentication.jwtToken();

    return token.map(addTokenBearerTo(config)).orElse(config);
  }
}

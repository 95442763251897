import { computed, inject, onMounted, onUnmounted, ref, ShallowRef, useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { BidderImageVue } from '@/common/primary/auction/bidder-image';
import { Club } from '@/fairplayer/domain/club/Club';
import { maxCardSize, maxSquaresIn } from '@/fairplayer/primary/live-auctions/maxCardSize';
import { LiveAuctionsCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-card';
import QrcodeVue from 'qrcode.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'LiveAuctionsPage',

  components: {
    QRCodeVue: QrcodeVue,
    FairplayerImageVue,
    BidderImageVue,
    LiveAuctionsCardVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const grid: ShallowRef<HTMLElement | null> = useTemplateRef('grid');
    const club = ref();
    const previousExclusives = ref<ExclusiveUi[]>([]);
    const exclusives = ref<ExclusiveUi[]>([]);
    const refreshExclusivesIntervalId = ref(0);
    const cardPxSize = ref(0);
    const maxSquares = ref(0);
    const canFitTotalCard = ref(false);
    const currentRoute = router.resolve({
      name: 'donationPage',
      params: {
        slug: route.params.slug,
      },
    });
    const auctionMarketplaceLink = computed(() => new URL(currentRoute.href, club.value.webappUrl).href);

    const newBid = (exclusiveId: string) =>
      previousExclusives.value.length > 0 &&
      (exclusives.value.find(e => e.id === exclusiveId)?.auctionLot?.bids?.length ?? 0) >
        (previousExclusives.value.find(e => e.id === exclusiveId)?.auctionLot?.bids?.length ?? 0);

    const sortByAuctionPriceDescThenAlphabetical = (e1: ExclusiveUi, e2: ExclusiveUi) => {
      const dif = e2.auctionLot!.currentPrice.totalCost.value - e1.auctionLot!.currentPrice.totalCost.value;
      if (dif === 0) {
        return e1.name.localeCompare(e2.name);
      }
      return dif;
    };

    const floor = (price: string) => price.replaceAll('.00 ', '').replaceAll(' ', '');

    const totalPrice = computed(() =>
      exclusives.value
        .filter(e => e.auctionLot!.bids?.length > 0)
        .reduce((total, exclusive) => exclusive.auctionLot!.currentPrice.totalCost.value + total, 0)
    );

    const autoRefreshExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || !exclusives.length) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadExclusives, 4000);
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        const exclusivesResponse = await exclusiveRepository.listBySlug(club.value.slug);
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.auctionLot.isPresent())
          .map(exclusive => fromExclusive(exclusive, club.value))
          .sort(sortByAuctionPriceDescThenAlphabetical);
        autoRefreshExclusives(mappedExclusives);
        const tmpExclusives = exclusives.value;
        exclusives.value = mappedExclusives;
        computeMaxCardSize();
        previousExclusives.value = tmpExclusives;
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value = [];
      }
    };

    const computeMaxCardSize = () => {
      const width = grid.value!.getBoundingClientRect().width;
      const height = grid.value!.getBoundingClientRect().height;
      cardPxSize.value = maxCardSize(width, height, exclusives.value.length + 1);
      maxSquares.value = maxSquaresIn(width, height, cardPxSize.value);
      canFitTotalCard.value = maxSquares.value - exclusives.value.length - 1 > 0;
    };

    onMounted(() => {
      clubRepository.retrieveClub().then((retrievedClub: Club) => {
        club.value = retrievedClub;
        return loadExclusives();
      });
      globalWindow.addEventListener('resize', computeMaxCardSize);
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
      globalWindow.removeEventListener('resize', computeMaxCardSize);
    });

    return {
      auctionMarketplaceLink,
      grid,
      floor,
      club,
      totalPrice,
      newBid,
      exclusives,
      cardPxSize,
      canFitTotalCard,
      t,
    };
  },
};

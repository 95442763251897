import { PropType } from 'vue';

export default {
  name: 'FairplayerButton',

  props: {
    icon: {
      type: String as PropType<string>,
      required: false,
    },
    expand: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    secondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<string>,
      default: 'button',
    },
  },

  setup(props: any) {
    const iconClasses = (): string => {
      if (props.loading) {
        return 'fp-glyph-loader -spin';
      }
      if (props.icon) {
        return `fp-glyph-${props.icon}`;
      }
      return '';
    };

    return { iconClasses };
  },
};

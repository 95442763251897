include ../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='members')
  template(v-slot:content v-if='interlocutorsMessages.isLoading()')
    .fp-chat-page
      .fp-chat-page--interlocutors
        .fp-interlocutors--header
          h2.fp-title {{ t('members.conversations') }}
      .fp-chat-page--chat
  template(v-slot:content v-if='!interlocutorsMessages.isLoading()')
    .fp-alert.-info(v-if='!membersList.value().length' data-test='alert')
      +fp-icon('info', {color: 'info', size: '18'})
      span.fp-text.-paragraph {{ t('members.alert.emptyMembers') }}
    .fp-chat-page(v-else)
      .fp-chat-page--interlocutors
        InterlocutorsListVue(:interlocutors-messages='interlocutorsMessages.value()' data-test='side-interlocutors')
      router-view(:key='route.fullPath' :members-list='membersList.value()' :interlocutors-messages='interlocutorsMessages.value()' @updated-conversation='loadMessages' data-test='router-view')

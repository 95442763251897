include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-sticky-pane(data-test='donation-page')
      .fp-sticky-pane--content(v-if='exclusives.isLoading()')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-placeholder.-height-sm
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols3.-cols2-up-md
              .fp-responsive-grid--column(v-for='(_, index) in 6')
                .fp-placeholder.-height-xl
      .fp-sticky-pane--content(v-if='!exclusives.isLoading()')
        .fp-vertical-spacing.-s-xl
          .fp-vertical-spacing--line(v-if='!currentExclusives.length' data-test='empty')
            .fp-vertical-spacing
              .fp-vertical-spacing--line.-align-center
                +fp-icon('info', {color: 'shade-300', size: '50'})
              .fp-vertical-spacing--line.-align-center
                h2.fp-title {{ t('oneTimeDonation.noExclusive') }}
              .fp-vertical-spacing--line.-align-center
                .fp-text {{ t('oneTimeDonation.comebackLater') }}
          .fp-vertical-spacing--line(v-if='currentExclusives.length')
            .fp-vertical-spacing
              .fp-vertical-spacing--line
                h2.fp-title {{ t('oneTimeDonation.featured') }}
              LostAuctionsVue(:exclusives='currentExclusives')
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols3.-cols2-up-lg.-s-sm-up-sm
                  .fp-responsive-grid--column(v-for='(exclusive, index) in currentExclusives' :key='index' data-test='current-exclusives')
                    router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                      PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
          .fp-vertical-spacing--line(v-if='passedExclusives.length')
            .fp-vertical-spacing
              .fp-vertical-spacing--line
                h2.fp-title {{ t('oneTimeDonation.passedAuctions') }}
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols3.-cols2-up-lg.-s-sm-up-sm
                  .fp-responsive-grid--column(v-for='(exclusive, index) in passedExclusives' :key='index' data-test='passed-exclusives')
                    router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                      PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
      aside.fp-sticky-pane--aside
        form.fp-vertical-spacing(@submit.prevent='createDonation' data-test='create-donation-form')
          .fp-vertical-spacing--line
            h2.fp-title {{ t('oneTimeDonation.contribute') }}
          .fp-vertical-spacing--line
            .fp-card
              .fp-vertical-spacing
                .fp-vertical-spacing--line
                  .fp-text.-paragraph {{ t('oneTimeDonation.support') }}
                .fp-vertical-spacing--line
                  label.fp-field
                    | {{ t('oneTimeDonation.amountDescription') }}
                    .fp-icons-input
                      label.fp-icons-input--right-icon(for='donation-amount')
                        i.fp-icon.fp-glyph-euro.-s16
                      input.fp-input-text#donation-amount(type='number' min='1' :placeholder=`t('oneTimeDonation.amount')` v-model='amount' required data-test='amount')
                .fp-vertical-spacing--line
                  FairplayerButtonVue(v-if='isAuthenticated.isLoading() || isAuthenticated.value()' icon='hand-heart' expand type='submit' :loading='isCreatingOneTimeDonation' :text='t("oneTimeDonation.makeDonation")' data-test='create-donation')
                  LoginVue(v-else icon='hand-heart' translate-key='oneTimeDonation.makeDonation')
                .fp-vertical-spacing--line(v-if='paymentFailed' data-test='payment-failed')
                  .fp-alert.-danger
                    +fp-icon('close-circle', {color: 'danger', size: '18'})
                    span.fp-text.-text-sm {{ t('oneTimeDonation.paymentFailed') }}
  template(v-slot:footer v-if='!exclusives.isLoading() && liveAuctionLot')
    LiveAuctionFooterVue(:auction='liveAuctionLot.auction')

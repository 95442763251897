const invalidDate = () => {
  throw new Error('LocalDate should be in valid format: YYYY-MM-DD');
};

const assertValidDate = (iso: string) => {
  if (iso === '') {
    invalidDate();
  }

  if (isNaN(Date.parse(iso))) {
    invalidDate();
  }

  if (new Date(iso).toISOString().substring(0, 10) !== iso) {
    invalidDate();
  }
};

export class LocalDate {
  static readonly EPOCH_YEAR = 1970;
  readonly year: number;
  readonly month: number;
  readonly day: number;

  constructor(readonly iso: string) {
    const trimIso = iso.trim();
    assertValidDate(trimIso);

    const [year, month, day] = trimIso.split('-');
    this.year = +year;
    this.month = +month;
    this.day = +day;
  }

  static of(iso: string): LocalDate {
    return new LocalDate(iso);
  }

  static fromDate(date: Date): LocalDate {
    return LocalDate.of(date.toISOString().slice(0, 10));
  }

  toDate(): Date {
    return new Date(this.year, this.month - 1, this.day);
  }

  equal(other: LocalDate): boolean {
    return this.iso === other.iso;
  }

  compare(other: LocalDate): number {
    if (this.equal(other)) {
      return 0;
    } else if (this.iso < other.iso) {
      return -1;
    }
    return 1;
  }

  toHuman(locale: string): string {
    const date = new Date(this.iso);
    return date.toLocaleDateString(locale, { timeZone: 'UTC' });
  }

  toHumanLiteral(locale: string): string {
    return new Date(this.iso).toLocaleDateString(locale, {
      dateStyle: 'long',
      timeZone: 'UTC',
    });
  }

  toHumanMonthAndYear(locale: string): string {
    const date = new Date(this.iso);

    const monthAndYear = date.toLocaleDateString(locale, {
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC',
    });

    return monthAndYear.charAt(0).toLocaleUpperCase(locale) + monthAndYear.slice(1);
  }

  weekday(locale: string): string {
    const date = new Date(this.iso);
    return date.toLocaleDateString(locale, { weekday: 'long', timeZone: 'UTC' });
  }

  isInThePast(): boolean {
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    return new Date(this.iso) < todayMidnight;
  }

  calculateAge(): number {
    const date = new Date(this.iso);
    return new Date(+new Date() - +date).getFullYear() - LocalDate.EPOCH_YEAR;
  }
}

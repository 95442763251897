import { InjectionKey } from 'vue';
import { Optional } from '@/common/domain/Optional';

export const themeRepositoryKey: InjectionKey<ThemeRepository> = Symbol();

export interface ThemeRepository {
  get(): Optional<string>;
  save(theme: string): void;
  set(theme: string): void;
}

import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FaqCard',

  props: {
    cardId: {
      type: String as PropType<string>,
      required: true,
    },
    link: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

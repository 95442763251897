import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ExclusiveDetailHeadingVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-detail-heading';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'HiddenExclusive',

  components: { ExclusiveDetailHeadingVue, FairplayerImageVue },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup() {
    const { d, t } = useI18n();
    const router = useRouter();

    const goToMarketplace = async () => {
      await router.push({ name: 'marketplace' });
    };

    return { goToMarketplace, d, t };
  },
});

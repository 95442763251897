router-link.fp-interlocutor(:to='{name: \'chatPage\', params: { memberId: conversation.interlocutorId }}' :class='{"-active": active}')
  .fp-interlocutor--picture
    .fp-image.-circled
      FairplayerImageVue(:url='conversation.interlocutorPictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
  .fp-interlocutor--summary
    span.fp-text.-text-semi-bold.-color-shade-900(data-test='interlocutor-name') {{ conversation.interlocutorFirstName }} {{ conversation.interlocutorLastName }}
    .fp-text.-color-shade-500.-lines-1.-text-sm(data-test='last-message')
      span(v-if='!lastMessage.received' data-test='you') {{ t('chat.you') }}&nbsp;
      | {{ lastMessage.text }}
  .fp-interlocutor--unread(v-if='conversation.numberOfUnreadMessages > 0' data-test='number-of-unread-message')
    .fp-chip {{ conversation.numberOfUnreadMessages }}

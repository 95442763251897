import { Logger } from '../domain/Logger';
import { LoggerMessage } from '../domain/LoggerMessage';

export default class ConsoleLogger implements Logger {
  constructor(private logger: Console) {}

  error(message: LoggerMessage, error: Error) {
    this.logger.error(`${message}\n`, error);
  }

  warn(message: LoggerMessage, error: Error) {
    this.logger.warn(`${message}\n`, error);
  }
}

import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { BackendMedia, toMedia } from '@/fairplayer/secondary/exclusive/backend/BackendMedia';
import { Optional } from '@/common/domain/Optional';
import { BackendAuctionLot, toAuctionLot } from '@/fairplayer/secondary/exclusive/backend/BackendAuctionLot';

export interface BackendExclusive {
  id: string;
  name: string;
  slug: string;
  description: string;
  price: BackendPrice;
  visible: boolean;
  imageUrl: string;
  medias: BackendMedia[];
  stock: number;
  category: ExclusiveCategory;
  auctionLot?: BackendAuctionLot;
}

export const toExclusive = (backendExclusive: BackendExclusive): Exclusive => ({
  id: backendExclusive.id,
  name: backendExclusive.name,
  description: backendExclusive.description.replaceAll('\\n', '\n'),
  slug: backendExclusive.slug,
  imageUrl: backendExclusive.imageUrl,
  medias: backendExclusive.medias.map(toMedia),
  pricing: toTokens(backendExclusive.price),
  visible: backendExclusive.visible,
  stock: backendExclusive.stock,
  category: backendExclusive.category,
  auctionLot: Optional.ofUndefinable(backendExclusive.auctionLot).map(toAuctionLot),
});

import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { Wallet } from '@/fairplayer/domain/Wallet';

export interface WalletUi {
  balance: TokensUi;
  isEmpty: boolean;
}

export const fromWallet = (wallet: Wallet): WalletUi => ({
  balance: fromTokens(wallet.balance),
  isEmpty: wallet.balance.coins.amount === 0,
});

import { useI18n } from 'vue-i18n';
import { computed, defineComponent, PropType, ref } from 'vue';
import { ComponentStep, Step } from '@/common/domain/Step';

export default defineComponent({
  name: 'Stepper',
  props: {
    defaultCurrentStep: {
      type: Number as PropType<number>,
      default: 0,
    },
    showProgressBar: {
      type: Function as PropType<Function>,
      default: () => true,
    },
    steps: {
      type: Array as PropType<ComponentStep[]>,
      required: true,
    },
  },

  emits: ['completed'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const currentStep = ref(props.defaultCurrentStep);
    const activeStep = computed(() => props.steps[currentStep.value]);
    const stepperTitleKey = ref<string>(activeStep.value.titleKey);
    const stepperTooltipKey = ref(activeStep.value.tooltipKey);
    const stepperPercentage = ref<number>(activeStep.value.percentage);

    const updateStepper = (step?: Step) => {
      stepperTitleKey.value = step?.titleKey || activeStep.value.titleKey;
      stepperTooltipKey.value = step?.tooltipKey || activeStep.value.tooltipKey;
      stepperPercentage.value = step?.percentage || activeStep.value.percentage;
    };

    const previousStep = () => {
      if (currentStep.value > 0) {
        currentStep.value--;
        updateStepper();
      }
    };

    const nextStep = () => {
      if (currentStep.value < props.steps.length - 1) {
        currentStep.value++;
        updateStepper();
      } else {
        emit('completed');
      }
    };

    return {
      activeStep,
      currentStep,
      nextStep,
      previousStep,
      stepperTitleKey,
      stepperTooltipKey,
      stepperPercentage,
      t,
      updateStepper,
    };
  },
});

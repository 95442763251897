import { Credential } from '@/common/domain/auth/Credential';

export interface LoginFormUi {
  email: string;
  password: string;
}

export const toCredential = (loginForm: LoginFormUi): Credential => ({
  email: loginForm.email,
  password: loginForm.password,
});

export const emptyLoginFormUi = () => ({
  email: '',
  password: '',
});

import { BackendMessage, toMessage } from '@/fairplayer/secondary/message/BackendMessage';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';

export interface BackendInterlocutorMessages {
  interlocutorId: string;
  messages: BackendMessage[];
  numberOfUnreadMessages: number;
}

export const toInterlocutorMessages = (messages: BackendInterlocutorMessages): InterlocutorMessages => ({
  interlocutorId: messages.interlocutorId,
  messages: messages.messages.map(toMessage),
  numberOfUnreadMessages: messages.numberOfUnreadMessages,
});

import { inject, onMounted, onUnmounted, ref } from 'vue';
import { ModalOpened } from '@/common/domain/modal/ModalOpened';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { globalWindowKey } from '@/common/domain/Window';
import { Unsubscribe } from '@/common/domain/Unsubscribe';

export default {
  name: 'Modal',

  setup() {
    const globalWindow = inject(globalWindowKey)!;
    const modalBus = inject(modalBusKey)!;

    const isModalOpened = ref<boolean>(false);
    const closableByUser = ref<boolean>(false);
    const modal = ref();
    let unsubscribeOpenModal: Unsubscribe;
    let unsubscribeCloseModal: Unsubscribe;
    let unsubscribeUserCloseModal: Unsubscribe;

    const closeOnEscape = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        modalBus.userClose();
      }
    };

    onMounted(async () => {
      globalWindow.document.addEventListener('keyup', closeOnEscape);
      unsubscribeOpenModal = modalBus.opened(openModal);
      unsubscribeCloseModal = modalBus.closed(closeModal);
      unsubscribeUserCloseModal = modalBus.userClosed(userCloseModal);
    });

    onUnmounted(() => {
      globalWindow.document.removeEventListener('keyup', closeOnEscape);
      unsubscribeOpenModal();
      unsubscribeCloseModal();
      unsubscribeUserCloseModal();
    });

    const openModal = (modalOpened: ModalOpened) => {
      modal.value = modalOpened;
      closableByUser.value = modalOpened.closableByUser;
      isModalOpened.value = true;
    };

    const userCloseModal = () => {
      if (closableByUser.value) {
        isModalOpened.value = false;
      }
    };

    const closeModal = () => {
      isModalOpened.value = false;
    };

    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    return {
      isModalOpened,
      modal,
      openModal,
      closeModal,
      userCloseModal,
      stopPropagation,
    };
  },
};

import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';

export interface MediaUi {
  url: string;
  type: MediaType;
}

export const fromMedia = (media: Media): MediaUi => ({
  url: media.url,
  type: media.type,
});

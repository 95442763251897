import { Post } from '@/fairplayer/domain/post/Post';
import { fromMedia, MediaUi } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { MarkdownUi } from '@/common/primary/Markdown.ui';
import { renderMarkdown } from '@/common/primary/MarkdownRenderer';

export interface PostUi {
  slug: string;
  name: string;
  description: MarkdownUi;
  imageUrl: string;
  medias: MediaUi[];
  date: string;
  programSlug?: string;
}

export const fromPost = (post: Post, locale: string): PostUi => ({
  slug: post.slug,
  name: post.name,
  description: renderMarkdown(post.description),
  imageUrl: post.imageUrl,
  medias: post.medias.map(fromMedia),
  date: post.date.toHumanLiteral(locale),
  programSlug: post.programSlug.orElseUndefined(),
});

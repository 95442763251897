import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Club } from '@/fairplayer/domain/club/Club';
import { BackendClub, toClub } from '@/fairplayer/secondary/club/BackendClub';
import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';
import { BackendClubSummary, toClubSummary } from '@/fairplayer/secondary/club/BackendClubSummary';
import { PresignedUpload } from '@/fairplayer/domain/upload/PresignedUpload';
import { FileContentType } from '@/fairplayer/domain/upload/FileContentType';

export class BackendClubRepository {
  constructor(private backendCaller: BackendCaller) {}

  list(): Promise<ClubSummary[]> {
    return this.backendCaller.get<BackendClubSummary[]>('api/clubs').then(clubs => clubs.data.map(toClubSummary));
  }

  get(clubSlug: ClubSlug): Promise<Club> {
    return this.backendCaller.get<BackendClub>(`api/clubs/${clubSlug}`).then(club => toClub(club.data));
  }

  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload> {
    return this.backendCaller.post<PresignedUpload>(`api/clubs/${clubSlug}/presigned-uploads`, { contentType }).then(({ data }) => data);
  }
}

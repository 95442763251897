include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-align-center
        +fp-icon('check-circle', {color: 'success', size: '50'})
      .fp-vertical-spacing--line.-align-center
        h2.fp-title {{ t('marketplace.purchaseConfirmed.thanks') }}
      .fp-vertical-spacing--line.-align-center
        .fp-text {{ t('marketplace.purchaseConfirmed.emailConfirmation') }}
      .fp-separator.-secondary
      .fp-vertical-spacing--line.-justify-center
        router-link.fp-link.-no-text-decoration(:to='{name: \'clubHomepage\'}')
          FairplayerButtonVue(icon='home' expand :text='t("marketplace.purchaseConfirmed.backHome")' data-test='purchase-confirmed.button')
  template(v-slot:footer)
    .fp-text(data-test='purchase-confirmed.contact')
      span {{ t('marketplace.purchaseConfirmed.contact') }}
        MailToContactVue

form.fp-vertical-spacing.-s-xl#forgot-code-form(@submit.prevent='sendCode' data-test='forgot-code-form')
  .fp-vertical-spacing--line.-justify-center
    h1.fp-title(data-test='title') {{ t('login.forgotPassword') }}
  .fp-vertical-spacing--line.-justify-center
    span.fp-text(data-test='forgot-code-description') {{ t('login.askResetPassword') }}
  .fp-vertical-spacing--line.-justify-center
    .fp-icons-input
      label.fp-icons-input--left-icon(for='username')
        i.fp-icon.fp-glyph-mail.-color-shade-500.-s16
      input.fp-input-text#username(v-model='email' type='email' :placeholder=`t('login.email')` autocomplete='username' data-test='email' required)
  .fp-vertical-spacing--line
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        FairplayerButtonVue(:text=`t('login.continue')` type='submit' expand data-test='ask-forgot-code')
      .fp-vertical-spacing--line.-justify-center
        router-link.fp-link.-secondary(:to='{name: \'login\'}' data-test='login') {{ t('login.returnToLogin') }}

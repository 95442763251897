include ../../../../common/primary/icon/icon.mixin.pug

.fp-collapsible-card(data-test='donations')
  input#donation-list(type='checkbox', name='collapse')
  label.fp-collapsible-card--trigger(for='donation-list' data-test='donations.trigger')
    +fp-icon('hand-heart', {size: '16'})
    span.fp-text.-text-semi-bold {{ t('donations.title') }}
    +fp-icon('chevron-right', {size: '12'})
  .fp-collapsible-card--content
    .fp-vertical-spacing.-s-lg
      .fp-vertical-spacing--line(v-if='!hasDonations')
        .fp-text(data-test='no-donations') {{ t('donations.noDonations') }}
      .fp-vertical-spacing--line(v-for='(donation, donationIdx) in donationList' :key='`donation-${donationIdx}`')
        .fp-vertical-spacing.-s-xs
          .fp-vertical-spacing--line
            .fp-text.-text-semi-bold {{ donation.amount.text }}
          .fp-vertical-spacing--line
            .fp-text.-color-shade-500.-text-sm(data-test='date') {{ donation.date }}
      .fp-vertical-spacing--line
        a.fp-link(@click='goToDonationPage' data-test='redirect-to-donation-page') {{ t('donations.makeOneTimeDonation') }}

import { GiveawayParticipationToRegister } from '@/fairplayer/domain/giveaway/GiveawayParticipationToRegister';
import { InjectionKey } from 'vue';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';
import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';

export const giveawayParticipationRepositoryKey: InjectionKey<GiveawayParticipationRepository> = Symbol();

export interface GiveawayParticipationRepository {
  listFor(clubSlug: ClubSlug, username: Username): Promise<GiveawayParticipation[]>;
  register(giveawayParticipationToRegister: GiveawayParticipationToRegister): Promise<void>;
}

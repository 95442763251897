import { FairplayerButtonVue } from '@/common/primary/button';
import { NotFoundVue } from '@/common/primary/not-found';
import { partnerNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { GiveawayParticipationFormVue } from '@/fairplayer/primary/giveaway-participation/giveaway-participation-form';
import { PageVue } from '@/fairplayer/primary/page';

export default {
  name: 'GiveawayParticipationPage',

  components: { ClubLogoVue, FairplayerButtonVue, NotFoundVue, GiveawayParticipationFormVue, PageVue },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const clubRepository = inject(clubRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const partnerSlug = computed(() => route.query['partner'] as string);
    const partner = computed(() => club.partners.find(p => p.slug === partnerSlug.value));

    const goToMarketplace = async () => await router.push({ name: 'marketplace' });

    return {
      club,
      partner,
      partnerSlug,
      t,
      goToMarketplace,
      partnerNotFoundUi,
    };
  },
};

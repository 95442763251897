import { PasswordStrengthRetriever } from '@/common/domain/login/PasswordStrengthRetriever';
import { PasswordStrength } from '@/common/domain/login/PasswordStrength';
import { PasswordMeter } from 'password-meter';

export class PasswordMeterStrengthRetriever implements PasswordStrengthRetriever {
  retrieve(password: string): PasswordStrength {
    if (password?.length < 8) {
      return PasswordStrength.TOO_SHORT;
    }

    const result = new PasswordMeter(
      {},
      {
        80: PasswordStrength.WEAK,
        120: PasswordStrength.MEDIUM,
        180: PasswordStrength.STRONG,
        _: PasswordStrength.STRONG,
      }
    ).getResult(password);

    return result.status as PasswordStrength;
  }
}

import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { MemberUi } from '@/fairplayer/primary/members-page/Member.ui';

export default defineComponent({
  name: 'BidderIdentity',

  components: {
    FairplayerImageVue,
  },

  props: {
    bidder: {
      type: Object as PropType<MemberUi>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const ANONYMOUS = t('marketplace.auction.anonymousIdentity');

    const knownIdentity = () => `${props.bidder.firstName} ${props.bidder.lastName}`;

    const bidderIdentity = computed(() => (props.bidder.anonymous ? ANONYMOUS : knownIdentity()));

    return {
      t,
      bidderIdentity,
      FairplayerFallbackImageUi,
    };
  },
});

import { FiatOrderToCreate } from '@/fairplayer/domain/exclusive/FiatOrderToCreate';

interface BackendFiatOrderLineItem {
  exclusiveId: string;
}

export interface BackendFiatOrderToCreate {
  lineItems: BackendFiatOrderLineItem[];
  successUrl: string;
  cancelUrl: string;
}

export const fromFiatOrderToCreate = (order: FiatOrderToCreate): BackendFiatOrderToCreate => ({
  lineItems: [{ exclusiveId: order.exclusiveId }],
  successUrl: `${order.currentLocation}/purchase-confirmed`,
  cancelUrl: `${order.currentLocation}?payment-result=FAILURE`,
});

import { useI18n } from 'vue-i18n';
import { StepperVue } from '@/common/primary/stepper';
import { computed, defineComponent, PropType } from 'vue';
import { kycSteps } from '@/fairplayer/primary/kyc-stepper/KycStepperSteps';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { Step } from '@/common/domain/Step';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'KycStepper',

  components: {
    StepperVue,
  },

  props: {
    fan: {
      type: Object as PropType<Fan>,
      required: true,
    },
  },

  emits: ['completed'],

  setup(props: any, { emit }: any) {
    const { t } = useI18n();
    const route = useRoute();

    const fanCurrentStep = computed(() => {
      if (
        props.fan.kycStatus === KycStatus.VALIDATED ||
        (props.fan.kycStatus === KycStatus.DUE_DILIGENCE_APPROVED && route.query['payment-result'] === 'SUCCESS')
      ) {
        return 5;
      }

      if (props.fan.kycStatus === KycStatus.DUE_DILIGENCE_APPROVED || props.fan.kycStatus === KycStatus.PAYMENT_PENDING) {
        return 4;
      }

      if (props.fan.kycStatus === KycStatus.DUE_DILIGENCE_FILLED) {
        return 3;
      }

      if (props.fan.kycStatus === KycStatus.PERSONAL_INFO_FILLED) {
        return 2;
      }

      return 0;
    });

    const showProgressBar = (currentStep: Step) => currentStep.percentage !== 0;

    const onComplete = () => {
      emit('completed');
    };

    return {
      fanCurrentStep,
      onComplete,
      showProgressBar,
      steps: kycSteps,
      t,
    };
  },
});

import { defineComponent, inject, ref } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/fairplayer/primary/page';
import { emptySignupFormUi, SignupFormUi, toCredential } from '@/common/primary/auth/signup/SignupForm.ui';
import { loggerKey } from '@/common/domain/Logger';
import { NewPasswordVue } from '@/common/primary/auth/new-password';
import { NewPasswordUpdated } from '@/common/primary/auth/new-password/NewPasswordUpdated';
import { EmailConfirmationVue } from '@/common/primary/auth/email-confirmation';

export default defineComponent({
  name: 'Signup',
  components: { EmailConfirmationVue, PageVue, FairplayerButtonVue, NewPasswordVue },

  setup() {
    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;
    const { t } = useI18n();
    const twoFactorNeeded = ref(false);
    const invalidSignup = ref(false);
    const isSigningUp = ref(false);
    const signupForm = ref<SignupFormUi>(emptySignupFormUi());

    const disabledSignup = ref(false);

    const invalidSignupError = () => (error: any) => {
      invalidSignup.value = true;
      logger.error('Failed to signup', error);
    };

    const signUp = async () => {
      isSigningUp.value = true;
      authentication
        .signUp(toCredential(signupForm.value))
        .then(() => (twoFactorNeeded.value = true))
        .catch(invalidSignupError())
        .finally(() => (isSigningUp.value = false));
    };

    const updateNewPassword = (update: NewPasswordUpdated) => {
      signupForm.value.newPassword = update.password;
      disabledSignup.value = !update.valid;
    };

    const googleLogin = async () => await authentication.googleLogin();

    return {
      disabledSignup,
      googleLogin,
      invalidSignup,
      isSigningUp,
      signUp,
      signupForm,
      t,
      toCredential,
      twoFactorNeeded,
      updateNewPassword,
    };
  },
});

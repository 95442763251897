export enum RouterTab {
  DONATIONS = 'DONATIONS',
  HOMEPAGE = 'HOMEPAGE',
  LOGIN = 'LOGIN',
  MARKETPLACE = 'MARKETPLACE',
  MEMBERS = 'MEMBERS',
  NEWS = 'NEWS',
  PROFILE = 'PROFILE',
  PROGRAMS = 'PROGRAMS',
  WALLET = 'WALLET',
}

import { inject, ref, useSlots } from 'vue';
import { PageFooterVue } from '@/fairplayer/primary/page-footer';
import { pageScrollBusKey } from '@/common/domain/page-scroll/PageScrollBus';

export default {
  name: 'Page',

  components: {
    PageFooterVue,
  },

  setup() {
    const slots = useSlots();
    const pageScrollBus = inject(pageScrollBusKey)!;

    const page = ref();

    const scrolled = () => {
      if (page.value) {
        pageScrollBus.scrolled(page.value.scrollTop);
      }
    };

    return { page, scrolled, slots };
  },
};

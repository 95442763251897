.fp-vertical-spacing--line.-justify-center
  PasswordVue#new-password(placeholder-key='login.password' autocomplete='new-password' @updated='(event) => updatePassword(event.value)' data-test='new-password')
.fp-text.-color-warning.-text-sm.-text-light(v-if='trailingOrLeadingSpace' data-test='trailing-leading-space') {{ t('login.trailingOrLeadingSpace') }}
.fp-vertical-spacing--line.-align-center(v-if='password')
  .fp-progress
    .fp-progress--title(data-test='strength-title') {{ t(`login.strength.${strength.key}`) }}
    .fp-progress--progression
      .fp-progress-bar
        .fp-progress-bar--bar(:class='`${strength.className} -fill${strength.fill}`' data-test='progress-bar')
.fp-vertical-spacing--line.-justify-center
  PasswordVue#confirm-new-password(placeholder-key='login.confirmPassword' autocomplete='new-password' :alert='differentPassword' @updated='(event) => updateConfirmPassword(event.value)' data-test='confirm-new-password')
.fp-text.-color-warning.-text-sm.-text-light(v-if='differentPassword' data-test='different-password') {{ t('login.passwordsNotEqual') }}

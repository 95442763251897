import { InjectionKey } from 'vue';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { FileContentType } from '@/fairplayer/domain/upload/FileContentType';
import { PresignedUpload } from '@/fairplayer/domain/upload/PresignedUpload';

export const uploadRepositoryKey: InjectionKey<UploadRepository> = Symbol();

export interface UploadRepository {
  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload>;
  uploadFileToStorage(file: File, presignedUrl: string): Promise<void>;
}

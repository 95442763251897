import { InjectionKey } from 'vue';
import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';

export const mobileGestureKey: InjectionKey<MobileGesture> = Symbol();

const LEFT_SWIPE_EVENT = 'left-swipe';
const RIGHT_SWIPE_EVENT = 'right-swipe';

export class MobileGesture {
  touchStartX: number;
  touchStartY: number;

  constructor(
    private emitter: Emitter<any>,
    private globalWindow: Window
  ) {
    this.touchStartX = 0;
    this.touchStartY = 0;
  }

  touchStart(touchStartX: number, touchStartY: number): void {
    this.touchStartX = touchStartX;
    this.touchStartY = touchStartY;
  }

  touchEnd(touchEndX: number, touchEndY: number): void {
    const limit = Math.tan(((45 * 1.5) / 180) * Math.PI);
    const x = touchEndX - this.touchStartX;
    const y = touchEndY - this.touchStartY;
    const yx = Math.abs(y / x);
    const pageWidth = this.globalWindow.innerWidth;
    const threshold = Math.max(1, Math.floor(0.01 * pageWidth));
    if (Math.abs(x) > threshold || Math.abs(y) > threshold) {
      if (yx <= limit) {
        if (x < 0) {
          this.emitter.emit(LEFT_SWIPE_EVENT);
        } else {
          this.emitter.emit(RIGHT_SWIPE_EVENT);
        }
      }
    }
  }

  onLeftSwipe = (swiped: () => void): Unsubscribe => {
    const handler: Handler<void> = () => swiped();
    this.emitter.on(LEFT_SWIPE_EVENT, handler);
    return () => this.emitter.off(LEFT_SWIPE_EVENT, handler);
  };

  onRightSwipe = (swiped: () => void): Unsubscribe => {
    const handler: Handler<void> = () => swiped();
    this.emitter.on(RIGHT_SWIPE_EVENT, handler);
    return () => this.emitter.off(RIGHT_SWIPE_EVENT, handler);
  };
}

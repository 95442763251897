import { BackendCaller } from '@/common/secondary/BackendCaller';
import { Cookie } from '@/fairplayer/secondary/consent/CookieConsentEvent';

export class BackendFanConsentRepository {
  constructor(private backendCaller: BackendCaller) {}

  async post(cookie: Cookie): Promise<void> {
    await this.backendCaller.post<void>('api/consents', cookie);
  }
}

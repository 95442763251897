import { createApp } from 'vue';
import App from './common/primary/app/App.vue';
import { createWebappRouter } from './router/router';
import { getProvider, provideAll } from '@/providers';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { createFairplayerI18n } from '@/i18n';
import { initSentry } from '@/sentry';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import VueGtag from 'vue-gtag';

// jhipster-needle-main-ts-import

const app = createApp(App);
const clubRepository = getProvider(clubRepositoryKey);

const router = createWebappRouter(clubRepository);
app.use(router);
provideAll(app.provide);

initSentry(app);
app.use(createFairplayerI18n(clubRepository, getProvider(fanLanguageRepositoryKey)));

app.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
    },
    bootstrap: false,
  },
  router
);
// jhipster-needle-main-ts-provider

app.mount('#app');

import { GiveawayAnsweredQuestionAnswer } from '@/fairplayer/domain/giveaway/GiveawayAnsweredQuestionAnswer';

export interface BackendGiveawayAnsweredQuestionAnswer {
  id?: string;
  text?: string;
}

export const fromGiveawayAnsweredQuestionAnswer = (answer: GiveawayAnsweredQuestionAnswer): BackendGiveawayAnsweredQuestionAnswer => ({
  id: answer.id.orElseUndefined(),
  text: answer.text.orElseUndefined(),
});

import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';

export interface BackendClubSummary {
  name: string;
  platformName: string;
  slug: string;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
}

export const toClubSummary = (backendClubSummary: BackendClubSummary): ClubSummary => ({
  name: backendClubSummary.name,
  platformName: backendClubSummary.platformName,
  slug: backendClubSummary.slug,
  logoUrl: backendClubSummary.logoUrl,
  bannerUrl: backendClubSummary.bannerUrl,
  webappUrl: backendClubSummary.webappUrl,
});

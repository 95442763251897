include ../../../../../common/primary/icon/icon.mixin.pug

.fp-horizontal-spacing.-wrap.-s-sm
  .fp-horizontal-spacing--column(v-if='hasCategoriesFilters' v-for='(category, index) in filters.categories' :key='index' data-test='filters-active')
    span.fp-badge {{ t(`marketplace.categories.${category.toLowerCase()}`) }}
      span.fp-badge--action(:data-test='`remove-category-${index}`' @click='removeCategory(category)')
        +fp-icon('close', {size: '10'})
  .fp-horizontal-spacing--column(data-test='fairplayer-sort-filters' v-if='isNotDefaultCaseFilters')
    span.fp-badge {{ t(`marketplace.categories.sort.${filters.sortBy}.order.${filters.sortOrder}`) }}
      span.fp-badge--action(:data-test='`remove-category-${filters.sortOrder}`' @click='removeSortByFilter()')
        +fp-icon('close', {size: '10'})

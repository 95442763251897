import { InjectionKey } from 'vue';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { Logger } from '@/common/domain/Logger';

export const manifestHandlerKey: InjectionKey<ManifestHandler> = Symbol();

export class ManifestHandler {
  constructor(
    private window: Window,
    private clubRepository: ClubRepository,
    private logger: Logger
  ) {}

  addDomContentListener(): void {
    this.window.document.addEventListener('DOMContentLoaded', () => this.updateManifest());
  }

  private async updateManifest(): Promise<void> {
    const clubSlug = this.clubRepository.getCurrentSlug();
    try {
      const manifestExistResponse = await fetch(`/manifest-${clubSlug}.webmanifest.json`);
      this.setManifestLink(manifestExistResponse, clubSlug);
    } catch (error: any) {
      this.logger.error('Failed to retrieve manifest', error);
    }
  }

  private setManifestLink(manifestExistResponse: Response, clubSlug: string): void {
    const manifestLink = this.window.document.querySelector('link[rel="manifest"]');

    if (manifestExistResponse.headers.get('content-type')?.includes('json')) {
      manifestLink?.setAttribute('href', `/manifest-${clubSlug}.webmanifest.json`);
    }
  }
}

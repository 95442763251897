export interface CountdownUi {
  daysLeft: string;
  hoursLeft: string;
  minutesLeft: string;
  secondsLeft: string;
}

const toPositiveFloor = (number: number) => Math.floor(number < 0 ? 0 : number).toString();

export const fromMillisecondsLeft = (milliseconds: number): CountdownUi => ({
  daysLeft: toPositiveFloor(milliseconds / (1000 * 60 * 60 * 24)),
  hoursLeft: toPositiveFloor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).padStart(2, '0'),
  minutesLeft: toPositiveFloor((milliseconds % (1000 * 60 * 60)) / (1000 * 60)).padStart(2, '0'),
  secondsLeft: toPositiveFloor((milliseconds % (1000 * 60)) / 1000).padStart(2, '0'),
});

import { computed, inject, onMounted, onUnmounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/fairplayer/primary/page';
import { euro } from '@/common/domain/token/Fiat';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { PresentationCardVue } from '@/fairplayer/primary/presentation-card';
import { useRoute, useRouter } from 'vue-router';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { LoginVue } from '@/common/primary/auth/login';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { LiveAuctionFooterVue } from '@/fairplayer/primary/donation-page/live-auction-footer';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { LostAuctionsVue } from '@/fairplayer/primary/donation-page/lost-auctions';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { AuctionLotUi } from '@/fairplayer/primary/marketplace/AuctionLot.ui';

export default {
  name: 'DonationPage',

  components: {
    ExclusivePriceVue,
    FairplayerButtonVue,
    FairplayerImageVue,
    LostAuctionsVue,
    LiveAuctionFooterVue,
    LoginVue,
    PageVue,
    PresentationCardVue,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;
    const windowScroller = inject(windowScrollerKey)!;
    const router = useRouter();

    const exclusives = ref(Loader.loading<ExclusiveUi[]>());
    const amount: Ref<number | undefined> = ref();
    const isCreatingOneTimeDonation = ref(false);
    const refreshExclusivesIntervalId = ref(0);

    const isAuthenticated = ref(Loader.loading<boolean>());

    const sortByNumberThenStartingPriceDescThenName = (e1: ExclusiveUi, e2: ExclusiveUi) => {
      const difNumber = (e1.auctionLot!.number || 0) - (e2.auctionLot!.number || 0);
      if (difNumber !== 0) {
        return difNumber;
      }

      const difPrice = e2.auctionLot!.startingPrice.totalCost.value - e1.auctionLot!.startingPrice.totalCost.value;
      if (difPrice !== 0) {
        return difPrice;
      }

      return e1.name.localeCompare(e2.name);
    };

    const sortByPriceDescThenName = (exclu1: ExclusiveUi, exclu2: ExclusiveUi) => {
      const dif = exclu2.pricing.totalCost.value - exclu1.pricing.totalCost.value;
      if (dif === 0) {
        return exclu1.name.localeCompare(exclu2.name);
      }
      return dif;
    };

    const currentExclusives = computed(() => {
      const withEndDate = exclusives.value
        .value()
        .filter(exclusive => exclusive.auctionLot && exclusive.auctionLot.auction.endDate > new Date())
        .sort(sortByNumberThenStartingPriceDescThenName);

      const withoutEndDate = exclusives.value
        .value()
        .filter(exclusive => !exclusive.auctionLot)
        .sort(sortByPriceDescThenName);

      return [...withEndDate, ...withoutEndDate];
    });

    const liveAuctionLot = computed<AuctionLotUi | undefined>(() => {
      return exclusives.value
        .value()
        .map(exclusive => exclusive.auctionLot)
        .find(lot => lot?.auction.status === AuctionStatus.ACTIVE);
    });

    const passedExclusives = computed(() =>
      exclusives.value
        .value()
        .filter(exclusive => exclusive.auctionLot && exclusive.auctionLot.auction.endDate < new Date())
        .sort(sortByNumberThenStartingPriceDescThenName)
    );

    const paymentFailed = computed(() => route.query['payment-result'] === 'FAILURE');

    const createDonation = async () => {
      isCreatingOneTimeDonation.value = true;
      const clubSlug = clubRepository.getCurrentSlug();
      const currentLocation = globalWindow.location.href.split('?')[0];
      donationRepository
        .create({ clubSlug, amount: euro(+amount.value!), currentLocation })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch((error: any) => {
          logger.error('Failed to create donation', error);
          isCreatingOneTimeDonation.value = false;
        });
    };

    const autoRefreshExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || !exclusives.length) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadExclusives, 10000);
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        let fanId = undefined;
        let likes: ExclusiveId[] = [];
        if (isAuthenticated.value.value()) {
          const fan = await fanRepository.getForClub(clubRepository.getCurrentClub());
          fanId = fan.id;
          likes = await exclusiveRepository.listLikes(clubRepository.getCurrentSlug());
        }
        const exclusivesResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.stock > 0)
          .map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub(), fanId, likes.includes(exclusive.id)));
        autoRefreshExclusives(mappedExclusives);
        exclusives.value.loaded(mappedExclusives);
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value.loaded([]);
      }
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });

      await loadExclusives();

      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
    });

    return {
      liveAuctionLot,
      paymentFailed,
      passedExclusives,
      currentExclusives,
      exclusives,
      amount,
      createDonation,
      isAuthenticated,
      isCreatingOneTimeDonation,
      t,
    };
  },
};

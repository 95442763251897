import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import { Cookie } from '@/fairplayer/secondary/consent/CookieConsentEvent';
import { FanLanguageRepository } from '@/common/domain/FanLanguageRepository';

export class CookieConsentRepository {
  constructor(
    private fanLanguageRepository: FanLanguageRepository,
    private window: Window
  ) {}

  async run() {
    await CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
        },
      },
      language: {
        default: this.fanLanguageRepository.getCurrentLanguage(),
        translations: {
          en,
          fr,
        },
      },
    });
  }

  eraseAnalyticsCookies(): void {
    CookieConsent.eraseCookies([/^_ga/], '/', this.upperDomain());
  }

  retrieveConsentCookie(): Cookie {
    return CookieConsent.getCookie();
  }

  show(): void {
    CookieConsent.show(true);
  }

  private upperDomain(): string {
    const domainParts = this.window.location.hostname.split('.');
    if (domainParts.length <= 2) {
      return this.window.location.hostname;
    }

    return domainParts.slice(-2).join('.');
  }
}

import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';
import { LoginContext } from '@/common/domain/login/LoginContext';

export const LOGIN_REDIRECT_URL_KEY = 'login_redirect_url';

export class StorageLoginContextRepository implements LoginContextRepository {
  constructor(private storage: Storage) {}

  storeLoginContext(context: LoginContext): void {
    this.storage.setItem(LOGIN_REDIRECT_URL_KEY, context.redirectUrl);
  }

  retrieveLoginContext(): LoginContext {
    return {
      redirectUrl: this.storage.getItem(LOGIN_REDIRECT_URL_KEY)!,
    };
  }
}

import { LocalDate } from '@/common/domain/LocalDate';
import { Optional } from '@/common/domain/Optional';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { BackendCompanyInfo } from '@/fairplayer/secondary/fan/BackendCompanyInfo';

export interface BackendFanPersonalInfo {
  birthdate: string;
  birthplace: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyInfo?: BackendCompanyInfo;
}

export const toFanPersonalInfo = (backendFanPersonalInfo: BackendFanPersonalInfo | null): Optional<FanPersonalInfo> => {
  if (backendFanPersonalInfo === null) {
    return Optional.empty();
  }

  const fanPersonalInfo: FanPersonalInfo = {
    birthdate: LocalDate.of(backendFanPersonalInfo.birthdate),
    birthplace: backendFanPersonalInfo.birthplace,
    firstName: backendFanPersonalInfo.firstName,
    lastName: backendFanPersonalInfo.lastName,
    phoneNumber: backendFanPersonalInfo.phoneNumber,
    companyInfo: Optional.ofUndefinable(backendFanPersonalInfo.companyInfo),
  };
  return Optional.of(fanPersonalInfo);
};

export const fromFanPersonalInfo = (fanPersonalInfo: FanPersonalInfo): BackendFanPersonalInfo => ({
  birthdate: fanPersonalInfo.birthdate.iso,
  birthplace: fanPersonalInfo.birthplace,
  firstName: fanPersonalInfo.firstName,
  lastName: fanPersonalInfo.lastName,
  phoneNumber: fanPersonalInfo.phoneNumber,
  companyInfo: fanPersonalInfo.companyInfo.orElseUndefined(),
});

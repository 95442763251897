.fp-page
  .fp-page--content(ref='page' @scroll='scrolled' data-test='page.content')
    template(v-if='slots.content')
      slot(name='content')
    template(v-else)
      .fp-page--content-header(v-if='slots.header')
        slot(name='header')
      .fp-page--content-wrapper#page-wrapper(v-if='slots.body')
        slot(name='body')
      .fp-page--content-footer
        PageFooterVue
  .fp-page--footer(v-if='slots.footer')
    slot(name='footer')

import { createRouter, createWebHistory } from 'vue-router';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { computeRoutes } from '@/router/routes';
import { createRedirectGuards } from '@/router/redirectGuards';

export const createWebappRouter = (clubRepository: ClubRepository) => {
  const router = createRouter({
    history: createWebHistory(),
    routes: computeRoutes(clubRepository),
  });

  createRedirectGuards(router);

  return router;
};

import { AnnouncementType } from '@/common/primary/announcement/AnnouncementType';
import { RouteLocationRaw } from 'vue-router';

export interface AnnouncementUi {
  messageKey: string;
  showCloseAction: boolean;
  type: AnnouncementType;
  messageArguments?: Record<string, any>;
  routeLocation?: RouteLocationRaw;
  routeLink?: string;
  linkKey?: string;
}

export const createDevEnvironmentAnnouncementUi = (): AnnouncementUi => ({
  messageKey: 'announcement.dev.message',
  showCloseAction: true,
  type: AnnouncementType.INFO,
  routeLink: 'https://app.fairplayer.co',
  linkKey: 'announcement.dev.link',
});

export const createArchivedClubAnnouncementUi = (): AnnouncementUi => ({
  messageKey: 'announcement.club.archived',
  showCloseAction: false,
  type: AnnouncementType.WARNING,
});

export const createPaymentsDueAnnouncementUi = (paymentsDueNumber: number, firstExclusiveSlug: string): AnnouncementUi => {
  const routeLocation =
    paymentsDueNumber > 1 ? { name: 'profile' } : { name: 'exclusiveDetail', params: { exclusiveSlug: firstExclusiveSlug } };

  return {
    messageKey: 'announcement.paymentsDue.message',
    messageArguments: { count: paymentsDueNumber },
    showCloseAction: false,
    type: AnnouncementType.SUCCESS,
    routeLocation,
    linkKey: 'announcement.paymentsDue.link',
  };
};

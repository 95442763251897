import { PostRepository } from '@/fairplayer/domain/post/PostRepository';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Post } from '@/fairplayer/domain/post/Post';
import { BackendPost, toPost } from '@/fairplayer/secondary/post/BackendPost';
import { PostSlug } from '@/fairplayer/domain/post/PostSlug';
import { Optional } from '@/common/domain/Optional';

export class BackendPostRepository implements PostRepository {
  private posts: Post[] = [];
  constructor(private backendCaller: BackendCaller) {}

  async list(clubSlug: ClubSlug): Promise<Post[]> {
    if (this.posts.length) {
      return this.posts;
    }

    this.posts = await this.backendCaller.get<BackendPost[]>(`api/clubs/${clubSlug}/posts`).then(({ data }) => data.map(toPost));

    return this.posts;
  }

  async getBySlug(clubSlug: ClubSlug, postSlug: PostSlug): Promise<Optional<Post>> {
    if (this.posts.length) {
      return Promise.resolve(Optional.ofUndefinable(this.posts.find(post => post.slug === postSlug)));
    }

    return await this.backendCaller
      .get<BackendPost>(`api/clubs/${clubSlug}/posts/${postSlug}`)
      .then(({ data }) => Optional.of(toPost(data)))
      .catch(() => Optional.empty());
  }

  async getByProgram(clubSlug: string, programSlug: string): Promise<Post[]> {
    if (this.posts.length) {
      return this.posts.filter(post => post.programSlug.orElse('') === programSlug);
    }

    return await this.backendCaller
      .get<BackendPost[]>(`api/clubs/${clubSlug}/posts`)
      .then(({ data }) => data.map(toPost).filter(post => post.programSlug.orElse('') === programSlug))
      .catch(() => []);
  }
}

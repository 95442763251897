import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { Language } from '@/common/domain/Language';
import { globalWindowKey } from '@/common/domain/Window';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'LanguageSelector',

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const clubLanguages = fromClub(clubRepository.getCurrentClub()).languages;

    const setLanguage = (language: Language) => {
      fanLanguageRepository.storeLanguage(language);
      globalWindow.location.reload();
    };

    return {
      clubLanguages,
      setLanguage,
      t,
    };
  },
});

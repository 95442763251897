import { Alert } from '@/common/domain/alert/Alert';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { Emitter, Handler } from 'mitt';
import { AlertMessageKey } from '@/common/domain/alert/AlertMessageKey';
import { Alerted } from '@/common/domain/alert/Alerted';
import { Unsubscribe } from '@/common/domain/Unsubscribe';

export class MittAlertBus implements AlertBus {
  constructor(private emitter: Emitter<any>) {}

  alert(alert: Alert): void {
    this.emitter.emit('alert', { message: alert.message, type: alert.type });
  }

  onAlert(alerted: Alerted): Unsubscribe {
    const handler: Handler<Alert> = alert => alerted(alert.message, alert.type);
    this.emitter.on<AlertMessageKey>('alert', handler);
    return () => this.emitter.off('alert', handler);
  }
}

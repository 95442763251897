import { Auction } from '@/fairplayer/domain/exclusive/Auction';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export interface BackendAuction {
  startDate: string;
  endDate: string;
  status: AuctionStatus;
  anonymous: boolean;
}

export const toAuction = (backendAuction: BackendAuction): Auction => ({
  startDate: new Date(backendAuction.startDate),
  endDate: new Date(backendAuction.endDate),
  status: backendAuction.status,
  anonymous: backendAuction.anonymous,
});

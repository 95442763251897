export interface NotFoundUi {
  buttonKey: string;
  routeName: string;
  titleKey: string;
}

export const homeNotFoundUi = () => ({
  buttonKey: 'notFound.homepage',
  routeName: 'homepage',
  titleKey: 'notFound.title',
});

export const exclusiveNotFoundUi = () => ({
  buttonKey: 'marketplace.notFound.goToMarketplace',
  routeName: 'marketplace',
  titleKey: 'marketplace.notFound.message',
});

export const clubNotFoundUi = () => ({
  buttonKey: 'clubSelection.notFound.goToClubSelection',
  routeName: 'homepage',
  titleKey: 'clubSelection.notFound.message',
});

export const partnerNotFoundUi = () => ({
  buttonKey: 'giveaway.partner.notFound.goToMarketplace',
  routeName: 'marketplace',
  titleKey: 'giveaway.partner.notFound.message',
});

export const programNotFoundUi = () => ({
  buttonKey: 'programs.page.notFound.goToPrograms',
  routeName: 'programs',
  titleKey: 'programs.page.notFound.message',
});

export const postNotFoundUi = () => ({
  buttonKey: 'posts.page.notFound.goToPosts',
  routeName: 'newsfeed',
  titleKey: 'posts.page.notFound.message',
});

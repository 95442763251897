import { defineComponent, inject, ref } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { PageVue } from '@/fairplayer/primary/page';
import { ForgotCodeVue } from '@/common/primary/auth/forgot-password/forgot-code';
import { ResetPasswordVue } from '@/common/primary/auth/forgot-password/reset-password';

export default defineComponent({
  name: 'ForgotPassword',
  components: { ForgotCodeVue, ResetPasswordVue, PageVue },

  setup() {
    const authentication = inject(authenticationKey)!;
    const { t } = useI18n();
    const forgotCodeStep = ref(true);
    const email = ref('');

    const askResetPassword = async (fanEmail: string) => {
      await authentication.askResetPassword(fanEmail);
      forgotCodeStep.value = false;
      email.value = fanEmail;
    };

    return {
      askResetPassword,
      email,
      forgotCodeStep,
      t,
    };
  },
});

import { fromWallet, WalletUi } from '@/fairplayer/primary/wallet/Wallet.ui';
import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { loggerKey } from '@/common/domain/Logger';

export default {
  name: 'PurchaseConfirmation',

  components: { FairplayerButtonVue, PageVue, FairplayerImageVue, ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const walletRepository = inject(walletRepositoryKey)!;

    const exclusive = ref(Loader.loading<ExclusiveUi | null>());
    const wallet = ref(Loader.loading<WalletUi>());
    const isBuyingExclusive = ref(false);

    const currentExclusiveSlug = computed(() => route.params.exclusiveSlug as string);

    const loadExclusive = async (): Promise<ExclusiveUi> => {
      const repoExclusive = await exclusiveRepository.getBySlug(clubRepository.getCurrentSlug(), currentExclusiveSlug.value);
      return repoExclusive.map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub())).orElseThrow();
    };

    onMounted(async () => {
      let exclusiveResponse = null;

      try {
        exclusiveResponse = await loadExclusive();
      } catch (error: any) {
        logger.error('Failed to retrieve exclusive', error);
      } finally {
        exclusive.value.loaded(exclusiveResponse);
      }

      wallet.value.loaded(fromWallet(await walletRepository.getForClub(clubRepository.getCurrentSlug())));
    });

    const buyExclusive = async () => {
      isBuyingExclusive.value = true;
      exclusiveRepository
        .createCoinsOrder(clubRepository.getCurrentSlug(), exclusive.value.value()!.id)
        .then(() => router.push({ name: 'purchaseConfirmed' }))
        .catch(error => logger.error('Failed to buy', error))
        .finally(() => {
          isBuyingExclusive.value = false;
        });
    };

    return {
      exclusive,
      isBuyingExclusive,
      wallet,
      t,
      buyExclusive,
    };
  },
};

import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { PageScrollBus } from '@/common/domain/page-scroll/PageScrollBus';

export class MittPageScrollBus implements PageScrollBus {
  constructor(private emitter: Emitter<any>) {}

  scrolled(px: number): void {
    this.emitter.emit('scroll', px);
  }

  onScroll(scrolled: (px: number) => void): Unsubscribe {
    const handler: Handler<number> = px => scrolled(px);
    this.emitter.on('scroll', handler);
    return () => this.emitter.off('scroll', handler);
  }
}

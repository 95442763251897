include ../../../../common/primary/icon/icon.mixin.pug

.fp-layout--highlighted-media(v-if='isMediaHighlighted' data-test='layout-highlighted-media' @click='closeHighlightedMedia')
  img.fp-highlighted-media(v-if='medias.medias[medias.activeIndex].type === \'IMAGE\'' :class='{"-sliding-right": slidingRight, "-sliding-left": slidingLeft}' :src='medias.medias[medias.activeIndex].url' @click='stopPropagation' data-test='highlighted-image')
  video.fp-highlighted-media(v-else controls autoplay @click='stopPropagation' :class='{"-sliding-right": slidingRight, "-sliding-left": slidingLeft}' data-test='highlighted-video')
    source(:src='medias.medias[medias.activeIndex].url' type='video/mp4')
  .fp-layout--highlighted-media--close(data-test='layout-highlighted-media.close')
    +fp-icon('close', {size: 24, pointer: true})
  .fp-layout--highlighted-media--previous(v-if='medias.activeIndex !== 0' @click='previous' data-test='layout-highlighted-media.previous')
    +fp-icon('chevron-left', {size: 24, pointer: true})
  .fp-layout--highlighted-media--next(v-if='medias.activeIndex !== medias.medias.length - 1' @click='next' data-test='layout-highlighted-media.next')
    +fp-icon('chevron-right', {size: 24, pointer: true})

import { Tokens } from '@/common/domain/token/Tokens';
import { CoinsUi, emptyCoinsUi, fromCoins } from '@/common/primary/token/CoinsUi';
import { emptyFiatUi, FiatUi, fromFiat } from '@/common/primary/token/FiatUi';

export interface TokensUi {
  coins: CoinsUi;
  totalCost: FiatUi;
  tokenCost: FiatUi;
}

export const emptyTokensUi = (): TokensUi => ({
  coins: emptyCoinsUi(),
  totalCost: emptyFiatUi(),
  tokenCost: emptyFiatUi(),
});

export const fromTokens = (tokens: Tokens, options = { fiatValueDigit: 2 }): TokensUi => ({
  coins: fromCoins(tokens.coins),
  totalCost: fromFiat(tokens.totalCost, options),
  tokenCost: fromFiat(tokens.tokenCost),
});

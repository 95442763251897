import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Program } from '@/fairplayer/domain/program/Program';
import { InjectionKey } from 'vue';
import { ProgramSlug } from '@/fairplayer/domain/program/ProgramSlug';
import { Optional } from '@/common/domain/Optional';

export const programRepositoryKey: InjectionKey<ProgramRepository> = Symbol();

export interface ProgramRepository {
  list(clubSlug: ClubSlug): Promise<Program[]>;
  getBySlug(clubSlug: ClubSlug, programSlug: ProgramSlug): Promise<Optional<Program>>;
}

import { Alert } from '@/common/domain/alert/Alert';
import { Alerted } from '@/common/domain/alert/Alerted';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { InjectionKey } from 'vue';

export const alertBusKey: InjectionKey<AlertBus> = Symbol();
export interface AlertBus {
  onAlert(alerted: Alerted): Unsubscribe;
  alert(alert: Alert): void;
}

import { Tokens } from '@/common/domain/token/Tokens';
import { OperationType } from '@/fairplayer/domain/operation/OperationType';

export interface Operation {
  type: OperationType;
  pricing: Tokens;
  date: Date;
}

export const isExpense = (operation: Operation) => operation.type === OperationType.COINS_ORDER;

export const isFiatCentric = (operation: Operation) => operation.type === OperationType.COINS_PURCHASE;

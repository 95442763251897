import { Member } from '@/fairplayer/domain/member/Member';

export interface MemberUi {
  id: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
  me: boolean;
  anonymous: boolean;
}

export const fromMember = (member: Member, me: boolean, anonymize?: boolean): MemberUi => ({
  id: member.id,
  firstName: member.firstName,
  lastName: member.lastName,
  pictureUrl: member.pictureUrl.orElseUndefined(),
  me,
  anonymous: anonymize === true && !me,
});

import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ExclusivesFilters, SortBy } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';

type SortExclusivesFn = (exclusives: ExclusiveUi[], filters: ExclusivesFilters) => ExclusiveUi[];

export const sortExclusivesByPrice = (exclusives: ExclusiveUi[], filters: ExclusivesFilters): ExclusiveUi[] => {
  return exclusives.sort((a, b) => {
    if (filters.sortOrder === 'asc') {
      return a.pricing.totalCost.value - b.pricing.totalCost.value;
    }
    return b.pricing.totalCost.value - a.pricing.totalCost.value;
  });
};

const mappedSortFunction: Record<SortBy, SortExclusivesFn> = {
  price: sortExclusivesByPrice,
};

export const sortByExclusiveFilters = (exclusives: ExclusiveUi[], filters: ExclusivesFilters): ExclusiveUi[] => {
  const exclusivesFiltered = mappedSortFunction[filters.sortBy](exclusives, filters);
  if (!filters.categories.length) {
    return exclusivesFiltered;
  }
  return exclusivesFiltered.filter(exclusive => filters.categories.includes(exclusive.category));
};

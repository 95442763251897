import { Authentication } from '@/common/domain/auth/Authentication';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Operation } from '@/fairplayer/domain/operation/Operation';
import { OperationRepository } from '@/fairplayer/domain/operation/OperationRepository';
import { BackendOperation, toOperations } from '@/fairplayer/secondary/operation/BackendOperation';

export class BackendOperationRepository implements OperationRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async list(clubSlug: ClubSlug): Promise<Operation[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    return this.backendCaller
      .get<BackendOperation[]>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/operations`)
      .then(({ data }) => toOperations(data));
  }
}

include ../../../../common/primary/icon/icon.mixin.pug

.fp-alert.-info(v-if='!isActive' data-test='no-active-giveaway')
  +fp-icon('info', {color: 'info', size: '18'})
  span.fp-text.-color-shade-900.-paragraph {{ t('giveaway.noActiveGiveaway') }}
form(v-else autocomplete='on' @submit.prevent='registerParticipation' data-test='participation-form')
  .fp-card
    .fp-vertical-spacing
      GiveawayParticipationQuizVue(v-if='activeGiveaway.questions.length' :questions='activeGiveaway.questions' :form-disabled='formDisabled' @answered='answered')
      .fp-vertical-spacing--line
        label.fp-field
          span.fp-text.-color-shade-900 {{ t('giveaway.firstName') }}
          span.fp-text.-color-danger &nbsp*
          input.fp-input-text(:placeholder='t(\'giveaway.yourFirstName\')' required v-model='firstNameInput' name='firstName' data-test='first-name-input' :disabled='formDisabled')
      .fp-vertical-spacing--line
        label.fp-field
          span.fp-text.-color-shade-900 {{ t('giveaway.lastName') }}
          span.fp-text.-color-danger &nbsp*
          input.fp-input-text(:placeholder='t(\'giveaway.yourLastName\')' required v-model='lastNameInput' name='lastName' data-test='last-name-input' :disabled='formDisabled')
      .fp-vertical-spacing--line
        label.fp-field
          span.fp-text.-color-shade-900 {{ t('giveaway.email') }}
          span.fp-text.-color-danger &nbsp*
          input.fp-input-text(:placeholder='t(\'giveaway.yourEmail\')' required v-model='emailInput' name='email' data-test='email-input' :disabled='formDisabled')
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-checkbox#giveaway-participation-tos(type='checkbox' required data-test='giveaway-participation-tos')
          .fp-checkable-element--label
            label.fp-text.-text-sm.-color-shade-900(for='giveaway-participation-tos') {{ t('giveaway.accept') }}&nbsp;
              a.fp-link(target='_blank' :href='`${staticFilesUri}/tos/Fairplayer-giveaways-tos.pdf`') {{ t('giveaway.tos') }}
      .fp-vertical-spacing--line
        FairplayerButtonVue(type='submit' expand icon='award' :text='t(\'giveaway.participate\')' data-test='participation-submit' :disabled='formDisabled')

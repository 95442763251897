import { defineComponent, inject, PropType } from 'vue';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { OrderUi } from '@/fairplayer/primary/order-list/Order.ui';

export default defineComponent({
  name: 'OrderPrice',

  props: {
    order: {
      type: Object as PropType<OrderUi>,
      required: true,
    },
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;
    const club = clubRepository.getCurrentClub();
    const fiatAccepted = club.fiatAccepted;

    return {
      fiatAccepted,
    };
  },
});

import { Tokens } from '@/common/domain/token/Tokens';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { Coins } from '@/common/domain/token/Coins';

export interface OrderCost {
  originalCost: Tokens;
  discount: Tokens;
  finalCost: Tokens;
  finalCoins: Tokens;
}

const ensurePositiveCoinsAmount = (coinsAmount: number) => {
  if (coinsAmount <= 0) {
    return 0;
  }
  return coinsAmount;
};

export const fromExclusiveAndFanBalance = (exclusive: Exclusive, fanWallet: Wallet | undefined): OrderCost => {
  const ticker = exclusive.pricing.coins.ticker;
  const fanCoins = fanWallet?.balance.coins.amount || 0;
  const finalCostAmount = ensurePositiveCoinsAmount(exclusive.pricing.coins.amount - fanCoins);
  const finalCoinsAmount = ensurePositiveCoinsAmount(fanCoins - exclusive.pricing.coins.amount);
  const discountCoinsAmount = exclusive.pricing.coins.amount - finalCostAmount;

  return {
    originalCost: exclusive.pricing,
    discount: Tokens.of(new Coins(discountCoinsAmount, ticker)).withTokenCost(exclusive.pricing.tokenCost),
    finalCost: Tokens.of(new Coins(finalCostAmount, ticker)).withTokenCost(exclusive.pricing.tokenCost),
    finalCoins: Tokens.of(new Coins(finalCoinsAmount, ticker)).withTokenCost(exclusive.pricing.tokenCost),
  };
};

import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { InjectionKey } from 'vue';
import { ComponentToPrint } from '@/common/domain/printer/ComponentToPrint';
import { BusCallback } from '@/common/domain/BusCallback';

export const printerBusKey: InjectionKey<PrinterBus> = Symbol();

export interface PrinterBus {
  print(componentToPrint: ComponentToPrint): void;
  printed(callback: BusCallback<ComponentToPrint>): Unsubscribe;
}

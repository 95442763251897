include ../../../common/primary/icon/icon.mixin.pug

.fp-dynamic-grid(v-if='club' ref='grid' data-test='grid')
  LiveAuctionsCardVue(v-for='(exclusive, index) in exclusives' :key='index' :card-px-size='cardPxSize' data-test='current-exclusives')
    .fp-dynamic-card(:data-test='`exclusive-${index}`')
      .fp-dynamic-card--image
        FairplayerImageVue(:url='exclusive.imageUrl')
        .fp-dynamic-card--image-badge(v-if='exclusive.auctionLot.bids.length > 0' :class='{"-success": newBid(exclusive.id), "-shaking": newBid(exclusive.id)}' data-test='active-last-bid')
          .fp-image.-circled.-sm
            BidderImageVue(:bidder='exclusive.auctionLot.bids[0].bidder')
          span.fp-text.-text-semi-bold.-text-xl(data-test='pricing') {{ floor(exclusive.auctionLot.bids[0].price.totalCost.text) }}
        .fp-dynamic-card--image-badge(v-if='exclusive.auctionLot.bids.length === 0' :class='{"-success": newBid(exclusive.id), "-shaking": newBid(exclusive.id)}' data-test='active-no-bid')
          +fp-icon('close-circle', {size: '16', color: 'shade-500'})
          span.fp-text.-text-semi-bold.-text-xl {{ floor(exclusive.pricing.totalCost.text) }}
      .fp-dynamic-card--left-tag
        span.fp-tag(v-if='exclusive.auctionLot.number' data-test='lot-number')
          span.fp-tag--text {{ t('marketplace.auction.lot') }} {{ exclusive.auctionLot.number }}
      .fp-dynamic-card--details
        .fp-dynamic-card--details--text(:title='exclusive.name') {{ exclusive.name }}
  LiveAuctionsCardVue(v-if='canFitTotalCard' :card-px-size='cardPxSize')
    .fp-card
      .fp-vertical-spacing.-s-sm.-h100.-justify-center
        .fp-vertical-spacing--line.-justify-center.-expand
          .fp-image.-fluid
            FairplayerImageVue(:url='club.logoUrl' contain)
        .fp-vertical-spacing--line
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line.-align-center
              .fp-text.-text-semi-bold.-text-xs Total
            .fp-vertical-spacing--line.-align-center
              .fp-text.-text-lg.-text-semi-bold(data-test='total-donations') {{ totalPrice }}€
  LiveAuctionsCardVue(:card-px-size='cardPxSize')
    .fp-card(style='background: #fff')
      QRCodeVue.fp-image.-fluid.-contain(:value='auctionMarketplaceLink' level='L' render-as='svg' background='#fff' foreground='#000')

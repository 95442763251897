import { defineComponent, inject, onMounted, onUnmounted, PropType, ref, useTemplateRef } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { pageScrollBusKey } from '@/common/domain/page-scroll/PageScrollBus';

export default defineComponent({
  name: 'BannerImage',

  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
  },

  setup() {
    const pageScrollBus = inject(pageScrollBusKey)!;

    const banner = useTemplateRef('banner');
    const bannerTranslateY = ref(0);
    let unsubscribePageScrollBus: Unsubscribe;

    const updateBannerTranslateY = (px: number) => {
      bannerTranslateY.value = px * 0.5;
    };

    onMounted(() => {
      unsubscribePageScrollBus = pageScrollBus.onScroll(updateBannerTranslateY);
    });

    onUnmounted(() => {
      unsubscribePageScrollBus();
    });

    return {
      banner,
      bannerTranslateY,
    };
  },
});

include ../../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line(v-if='displayCountdown' data-test='countdown')
  .fp-card
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-justify-center
        .fp-icon-with-text(v-if='auction.status === \'UPCOMING\'' data-test='upcoming')
          +fp-icon('clock', {size: '18'})
          .fp-icon-with-text--text
            .fp-icon-with-text--text-line
              span.fp-text.-text-semi-bold {{ t('marketplace.exclusiveDetail.auction.status.upcoming') }}
        .fp-icon-with-text(v-if='auction.status === \'ACTIVE\'' data-test='active')
          .fp-dot
          .fp-icon-with-text--text
            .fp-icon-with-text--text-line
              span.fp-text.-text-semi-bold {{ t('marketplace.exclusiveDetail.auction.status.active') }}
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-equivalent
          .fp-horizontal-spacing--column
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-lg.-text-semi-bold {{ countdownUi.daysLeft }}
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.days') }}
          .fp-horizontal-spacing--column
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-lg.-text-semi-bold {{ countdownUi.hoursLeft }}
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.hours') }}
          .fp-horizontal-spacing--column
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-lg.-text-semi-bold {{ countdownUi.minutesLeft }}
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.minutes') }}
          .fp-horizontal-spacing--column
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-lg.-text-semi-bold {{ countdownUi.secondsLeft }}
              .fp-vertical-spacing--line.-align-center
                span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.seconds') }}

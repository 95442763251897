.fp-vertical-spacing
  .fp-vertical-spacing--line
    h2.fp-title {{ t('wallet.operationsList.title') }}
  template(v-if='!operationsList.isLoading()')
    .fp-vertical-spacing--line(v-if='!operationsList.value().length')
      span.fp-text(data-test='wallet.operations-list.empty') {{ t('wallet.operationsList.emptyOperations') }}
    .fp-vertical-spacing--line(v-for='(operation, index) in operationsList.value()' :key='index')
      .fp-titled-card(:data-test='`wallet.operations-list.${index}`')
        .fp-titled-card--title
          .fp-titled-card--title-main(data-test='operation-date') {{ operation.date }}
          .fp-titled-card--title-info(data-test='operation-summary') {{ operation.summary }}
        .fp-card.-dark
          .fp-horizontal-spacing
            .fp-horizontal-spacing--column.-expand(data-test='operation-label')
              span.fp-text {{ t(`wallet.operationsList.types.${operation.type}`) }}
            .fp-horizontal-spacing--column(data-test='operation-pricing')
              span.fp-text.-text-semi-bold {{ operation.pricing }}

import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { BidUi } from '@/fairplayer/primary/marketplace/Bid.ui';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { BidderImageVue } from '@/common/primary/auction/bidder-image';
import { BidderIdentityVue } from '@/common/primary/auction/bidder-identity';

export default defineComponent({
  name: 'ExclusiveAuctionBid',

  components: {
    RelativeTimeVue,
    BidderIdentityVue,
    BidderImageVue,
  },

  props: {
    bid: {
      type: Object as PropType<BidUi>,
      required: true,
    },
    highlighted: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
      FairplayerFallbackImageUi,
    };
  },
});

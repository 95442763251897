import { ClubUi } from '@/fairplayer/primary/club/Club.ui';

export interface TosUi {
  id: string;
  url: string;
  checked: boolean;
  linkKey: string;
}

const createTos = (id: string, url: string, customLinkKey?: string): TosUi => ({
  id,
  url,
  checked: false,
  linkKey: customLinkKey ?? 'tos.link',
});

export const createTosForm = (club: ClubUi): TosUi[] => [
  createTos('Fairplayer-fiat-eula', club.eulaUrl),
  createTos('Fairplayer-fiat-sfc', club.sfcUrl),
  createTos('Privacy-policy', club.privacyPolicyUrl, 'tos.Privacy-policy.link'),
];

include ../../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='programs')
  template(v-slot:body)
    .fp-vertical-spacing.-s-lg(v-if='!programsList.isLoading()')
      .fp-vertical-spacing--line(v-if='!programsList.value().length' data-test='alert')
        .fp-alert.-info
          +fp-icon('info', {color: 'info', size: '18'})
          span.fp-text.-paragraph {{ t('programs.alert.emptyPrograms') }}
      template(v-if='programsList.value().length')
        .fp-vertical-spacing--line
          .fp-responsive-grid.-cols2.-cols1-up-md
            .fp-responsive-grid--column(v-for='(program, index) in programsList.value()' :key='index' :data-test='`program-${index}`' :id='program.slug')
              router-link.fp-link.-no-text-decoration(:to='{name: \'programPage\', params: { programSlug: program.slug }}')
                .fp-featured-content
                  img(:src='program.imageUrl' :alt='program.name')
                  .fp-featured-content--background
                  .fp-featured-content--description
                    .fp-text.-text-lg.-text-semi-bold(data-test='name') {{ program.name }}
                    .fp-text.-text-md.-lines-2(data-test='description') {{ program.description.raw }}

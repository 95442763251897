import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { InterlocutorsListItemVue } from '@/fairplayer/primary/members-page/members-list/interlocutors-list/interlocutors-list-item';
import { InterlocutorMessagesUi } from '@/fairplayer/primary/InterlocutorMessages.ui';

export default defineComponent({
  name: 'InterlocutorsList',

  components: { InterlocutorsListItemVue },

  props: {
    interlocutorsMessages: {
      type: Array as PropType<InterlocutorMessagesUi[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

import { Coins } from '@/common/domain/token/Coins';

export interface CoinsUi {
  amount: number;
  ticker: string;
  text: string;
}

export const fromCoins = (coins: Coins): CoinsUi => ({
  amount: coins.amount,
  ticker: coins.ticker.toUpperCase(),
  text: toTextWithSpaces(coins),
});

const toTextWithSpaces = (coins: Coins): string => {
  const amount = coins.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return `${amount} ${coins.ticker.toUpperCase()}`;
};

export const emptyCoinsUi = (): CoinsUi => ({
  amount: 0,
  ticker: '',
  text: '',
});

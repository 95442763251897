import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { BookingSlot } from '@/fairplayer/domain/booking/BookingSlot';
import { BackendBookingSlot, toBookingSlot } from '@/fairplayer/secondary/booking/BackendBookingSlot';
import { BookingSlotRepository } from '@/fairplayer/domain/booking/BookingSlotRepository';
import { BookingSlotId } from '@/fairplayer/domain/booking/BookingSlotId';

export class BackendBookingSlotRepository implements BookingSlotRepository {
  constructor(private backendCaller: BackendCaller) {}

  async list(clubSlug: ClubSlug): Promise<BookingSlot[]> {
    return this.backendCaller
      .get<BackendBookingSlot[]>(`api/clubs/${clubSlug}/available-booking-slots`)
      .then(exclusives => exclusives.data.map(toBookingSlot));
  }

  async book(clubSlug: ClubSlug, bookingSlotId: BookingSlotId): Promise<void> {
    await this.backendCaller.post(`api/clubs/${clubSlug}/booking-slots/${bookingSlotId}/reservations`);
  }
}

import { Fiat } from '@/common/domain/token/Fiat';

export interface FiatUi {
  value: number;
  currency: string;
  text: string;
}

interface FiatUiOptions {
  fiatValueDigit: number;
}

export const fromFiat = (fiat: Fiat, options = { fiatValueDigit: 2 }): FiatUi => ({
  value: fiat.value,
  currency: fiat.currency,
  text: toTextWithSpaces(fiat, options),
});

const toTextWithSpaces = (fiat: Fiat, options: FiatUiOptions): string => {
  const parts = fiat.value.toFixed(options.fiatValueDigit).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return `${parts.join('.')} ${fiat.currency}`;
};

export const emptyFiatUi = (): FiatUi => ({
  value: 0,
  currency: '',
  text: '',
});

import { defineComponent, PropType } from 'vue';
import { MediaUi } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'ExclusiveMedia',

  components: {
    FairplayerImageVue,
  },

  props: {
    media: {
      type: Object as PropType<MediaUi>,
      required: true,
    },
  },
});

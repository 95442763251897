import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusivesFilters } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';
import { filtersToSave, hasFiltersCategories, isDefaultFiltersCase } from '@/fairplayer/primary/marketplace/exclusives-list/handleFilters';
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default {
  name: 'ExclusivesListFilters',

  props: {
    filters: {
      type: Object as PropType<ExclusivesFilters>,
      required: true,
    },
  },

  setup(props: any) {
    const { t } = useI18n();
    const router = useRouter();

    const hasCategoriesFilters = computed<boolean>(() => hasFiltersCategories(props.filters));
    const isNotDefaultCaseFilters = computed(() => !isDefaultFiltersCase(props.filters));

    const setUpdatedFilters = async (updatedFilters: ExclusivesFilters) => {
      const query = filtersToSave(updatedFilters);
      await router.push({
        query,
      });
    };

    const removeCategory = async (categoryToRemove: ExclusiveCategory) => {
      const updatedFilters: ExclusivesFilters = {
        categories: props.filters.categories.filter((category: ExclusiveCategory) => category !== categoryToRemove),
        sortOrder: props.filters.sortOrder,
        sortBy: props.filters.sortBy,
      };
      await setUpdatedFilters(updatedFilters);
    };

    const removeSortByFilter = async () => {
      const updatedFilters: ExclusivesFilters = {
        categories: props.filters.categories,
        sortBy: 'price',
        sortOrder: 'desc',
      };
      await setUpdatedFilters(updatedFilters);
    };

    return {
      hasCategoriesFilters,
      isNotDefaultCaseFilters,
      t,
      removeCategory,
      removeSortByFilter,
    };
  },
};

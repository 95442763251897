include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        .fp-card(data-test='one-time-donation-payment-confirmed')
          .fp-vertical-spacing
            .fp-vertical-spacing--line.-align-center
              +fp-icon('check-circle', {color: 'success', size: '50'})
            .fp-vertical-spacing--line.-align-center
              h2.fp-title(data-test='title') {{ t('oneTimeDonation.paymentConfirmed.title') }}
            .fp-vertical-spacing--line
              span.fp-text.-paragraph {{ t('oneTimeDonation.paymentConfirmed.text') }}
            .fp-vertical-spacing--line
              router-link.fp-link.-no-text-decoration(:to='{name: \'clubHomepage\'}')
                FairplayerButtonVue(icon='home' expand :text='t("oneTimeDonation.paymentConfirmed.backHome")')

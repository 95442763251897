import { Message } from '@/fairplayer/domain/message/Message';

export interface BackendMessage {
  id: string;
  senderId: string;
  receiverId: string;
  text: string;
  date: string;
}

export const toMessage = (backendMessage: BackendMessage): Message => ({
  id: backendMessage.id,
  senderId: backendMessage.senderId,
  receiverId: backendMessage.receiverId,
  text: backendMessage.text,
  date: new Date(backendMessage.date),
});

import { GiveawayAnsweredQuestion } from '@/fairplayer/domain/giveaway/GiveawayAnsweredQuestion';
import {
  BackendGiveawayAnsweredQuestionAnswer,
  fromGiveawayAnsweredQuestionAnswer,
} from '@/fairplayer/secondary/giveaway/BackendGiveawayAnsweredQuestionAnswer';

export interface BackendGiveawayAnsweredQuestion {
  questionId: string;
  answer: BackendGiveawayAnsweredQuestionAnswer;
}

export const fromGiveawayAnsweredQuestion = (answeredQuestion: GiveawayAnsweredQuestion): BackendGiveawayAnsweredQuestion => ({
  questionId: answeredQuestion.questionId,
  answer: fromGiveawayAnsweredQuestionAnswer(answeredQuestion.answer),
});

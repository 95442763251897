import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { AuctionUi } from '@/fairplayer/primary/marketplace/Auction.ui';
import { fromMillisecondsLeft } from '@/common/primary/Countdown.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export default defineComponent({
  name: 'ExclusiveAuctionCountdown',

  props: {
    auction: {
      type: Object as PropType<AuctionUi>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const globalWindow = inject(globalWindowKey)!;

    const intervalId = ref(0);
    const now = ref(Date.now());

    const millisecondsLeft = computed(() => {
      if (props.auction.status === AuctionStatus.UPCOMING) {
        return props.auction.startDate.getTime() - now.value;
      }

      return props.auction.endDate.getTime() - now.value;
    });
    const countdownUi = computed(() => fromMillisecondsLeft(millisecondsLeft.value));
    const displayCountdown = computed(() => millisecondsLeft.value > 0 && props.auction.status !== AuctionStatus.CLOSED);

    onMounted(async () => {
      intervalId.value = globalWindow.setInterval(updateNow, 1000);
    });

    const updateNow = () => {
      now.value = Date.now();
    };

    onUnmounted(() => {
      globalWindow.clearInterval(intervalId.value);
    });

    return {
      countdownUi,
      displayCountdown,
      t,
    };
  },
});

import { defineComponent, inject, useSlots } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Logout',

  setup() {
    const authentication = inject(authenticationKey)!;
    const { t } = useI18n();
    const slots = useSlots();

    const logout = () => authentication.logout();

    return {
      logout,
      slots,
      t,
    };
  },
});

import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ClubLogo',

  props: {
    lg: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const logoUrl = clubRepository.getCurrentClub().logoUrl;

    return {
      logoUrl,
      t,
    };
  },
});

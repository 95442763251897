import { Coins } from '@/common/domain/token/Coins';

export interface BackendCoins {
  amount: number;
  ticker: string;
}

export const toCoins = (backendCoins: BackendCoins): Coins => {
  return new Coins(backendCoins.amount, backendCoins.ticker);
};

import { ExclusiveOrder } from '@/fairplayer/domain/fan/order/exclusive-order/ExclusiveOrder';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface ExclusiveOrderUi {
  id: string;
  name: string;
  imageUrl: string;
  price: TokensUi;
}

export const fromExclusiveOrder = (exclusiveOrder: ExclusiveOrder): ExclusiveOrderUi => ({
  id: exclusiveOrder.id,
  name: exclusiveOrder.name,
  imageUrl: exclusiveOrder.imageUrl,
  price: fromTokens(exclusiveOrder.pricing),
});

include ../../../../common/primary/icon/icon.mixin.pug

.fp-layout--bottom-modal(v-if='bottomModal' :class='{"-active": isActive}' data-test='layout-bottom-modal' @click='closeBottomModal')
  .fp-bottom-modal(data-test='bottom-modal' @click='stopPropagation')
    .fp-bottom-modal--header(data-test='header')
      i.fp-icon.-s16(:class='`fp-glyph-${bottomModal.icon}`')
      | {{ t(bottomModal.titleKey) }}
    .fp-bottom-modal--close(@click='closeBottomModal' data-test='bottom-modal.close')
      +fp-icon('close', {color:'shade-900', size: '16', pointer: true})
    .fp-bottom-modal--content
      component(:is='bottomModal.component' :options='bottomModal.options' @ready='delaySetActiveForSlideInAnimation')

import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { PrinterBus } from '@/common/domain/printer/PrinterBus';
import { ComponentToPrint } from '@/common/domain/printer/ComponentToPrint';
import { BusCallback } from '@/common/domain/BusCallback';

const PRINT = 'print';

export class MittPrinterBus implements PrinterBus {
  constructor(private emitter: Emitter<any>) {}

  print(component: ComponentToPrint): void {
    this.emitter.emit(PRINT, component);
  }

  printed(callback: BusCallback<ComponentToPrint>): Unsubscribe {
    const handler: Handler<ComponentToPrint> = componentToPrint => callback(componentToPrint);
    this.emitter.on(PRINT, handler);
    return () => this.emitter.off(PRINT, handler);
  }
}

import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendProgram, toProgram } from '@/fairplayer/secondary/program/BackendProgram';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Program } from '@/fairplayer/domain/program/Program';
import { ProgramRepository } from '@/fairplayer/domain/program/ProgramRepository';
import { ProgramSlug } from '@/fairplayer/domain/program/ProgramSlug';
import { Optional } from '@/common/domain/Optional';

export class BackendProgramRepository implements ProgramRepository {
  private programs: Program[] | undefined;
  constructor(private backendCaller: BackendCaller) {}

  async list(clubSlug: ClubSlug): Promise<Program[]> {
    if (this.programs) {
      return this.programs;
    }

    const retrievedPrograms = await this.backendCaller
      .get<BackendProgram[]>(`api/clubs/${clubSlug}/programs`)
      .then(clubs => clubs.data.map(toProgram));
    this.programs = retrievedPrograms;

    return this.programs;
  }

  getBySlug(clubSlug: ClubSlug, programSlug: ProgramSlug): Promise<Optional<Program>> {
    if (this.programs) {
      return Promise.resolve(Optional.ofUndefinable(this.programs.find(program => program.slug === programSlug)));
    }

    return this.backendCaller
      .get<BackendProgram>(`api/clubs/${clubSlug}/programs/${programSlug}`)
      .then(({ data }) => Optional.of(toProgram(data)))
      .catch(() => Optional.empty());
  }
}

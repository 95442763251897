include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing
  .fp-vertical-spacing--line
    .fp-alert.-info
      +fp-icon('info', {color: 'info', size: '18'})
      span.fp-text.-paragraph {{ t('bookingSlots.description') }}
.fp-card.-highlighted(v-if='bookingDates.isLoading()' data-test='loading')
  .fp-vertical-spacing
    .fp-vertical-spacing--line.-align-center
      .fp-horizontal-spacing.-items-center.-s-sm
        .fp-horizontal-spacing--column
          +fp-icon('chevron-left', {color: 'shade-300'})
        .fp-horizontal-spacing--column.-expand
          .fp-responsive-grid.-cols6.-cols4-up-lg.-cols3-up-md.-s-sm.-no-wrap
            .fp-responsive-grid--column(v-for='_ in 6')
              .fp-placeholder.-height-sm
        .fp-horizontal-spacing--column
          +fp-icon('chevron-right', {color: 'shade-300'})
    .fp-vertical-spacing--line.-align-center
      .fp-horizontal-spacing
        .fp-horizontal-spacing--column
        .fp-horizontal-spacing--column.-expand
          .fp-responsive-grid.-cols6.-cols4-up-lg.-cols3-up-md.-s-sm.-no-wrap
            .fp-responsive-grid--column(v-for='_ in 6')
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line(v-for='_ in 4')
                  .fp-placeholder.-height-sm
        .fp-horizontal-spacing--column
.fp-alert.-warning(v-else-if='!bookingDates.value().length' data-test='empty')
  +fp-icon('close-circle', {color: 'warning', size: '18'})
  span.fp-text.-text-sm {{ t('bookingSlots.empty') }}
.fp-card.-highlighted(v-else data-test='booking-slots')
  .fp-vertical-spacing
    .fp-vertical-spacing--line.-align-center
      .fp-horizontal-spacing.-items-center.-s-sm
        .fp-horizontal-spacing--column
          +fp-icon('chevron-left', {color: 'shade-300'})
        .fp-horizontal-spacing--column.-expand
          .fp-responsive-grid.-cols6.-cols4-up-lg.-cols3-up-md.-s-sm.-no-wrap
            .fp-responsive-grid--column(v-for='(bookingDate, i) in bookingDates.value()' :data-test='`date-${i}`')
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  span.fp-text.-text-semi-bold {{ bookingDate.weekDay }}
                .fp-vertical-spacing--line
                  span.fp-text {{ bookingDate.dayShortMonth }}
        .fp-horizontal-spacing--column
          +fp-icon('chevron-right', {color: 'shade-300'})
    .fp-vertical-spacing--line.-align-center
      .fp-horizontal-spacing
        .fp-horizontal-spacing--column
        .fp-horizontal-spacing--column.-expand
          .fp-responsive-grid.-cols6.-cols4-up-lg.-cols3-up-md.-s-sm.-no-wrap
            .fp-responsive-grid--column(v-for='(bookingDate, dateIndex) in bookingDates.value()')
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line(v-for='(slot, slotIndex) in bookingDate.slots')
                  .fp-badge.-clickable(@click='openBookingSlotConfirmation(slot)' :data-test='`date-${dateIndex}-slot-${slotIndex}`') {{ slot.startTime }}
        .fp-horizontal-spacing--column

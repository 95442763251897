import { defineComponent, inject, PropType } from 'vue';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { MediaType } from '@/common/domain/MediaType';

export default defineComponent({
  name: 'FairplayerVideo',

  props: {
    highlightable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    wide: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    expand: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    url: {
      type: String as PropType<string>,
      default: '',
      required: false,
    },
  },

  setup(props) {
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const onClick = (url: string) => {
      if (props.highlightable) {
        highlightedMediaBus.open({ medias: [{ url, type: MediaType.VIDEO }], activeIndex: 0 });
      }
    };

    return {
      onClick,
    };
  },
});

import { Order } from '@/fairplayer/domain/fan/order/Order';
import { BackendExclusiveOrder, toExclusiveOrder } from '@/fairplayer/secondary/fan/order/BackendExclusiveOrder';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';

export interface BackendOrder {
  id: string;
  exclusives: BackendExclusiveOrder[];
  clubSlug: string;
  date: string;
  originalCost: BackendPrice;
  usedCoins: BackendPrice;
  finalCost: BackendPrice;
}

export const toOrder = (backendOrder: BackendOrder): Order => ({
  id: backendOrder.id,
  exclusives: backendOrder.exclusives.map(toExclusiveOrder),
  clubSlug: backendOrder.clubSlug,
  date: new Date(backendOrder.date),
  originalCost: toTokens(backendOrder.originalCost),
  usedCoins: toTokens(backendOrder.usedCoins),
  finalCost: toTokens(backendOrder.finalCost),
});

import { Auction } from '@/fairplayer/domain/exclusive/Auction';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export interface AuctionUi {
  startDate: Date;
  endDate: Date;
  status: AuctionStatus;
  anonymous: boolean;
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  startDate: auction.startDate,
  endDate: auction.endDate,
  status: auction.status,
  anonymous: auction.anonymous,
});

import { BackendCaller } from '@/common/secondary/BackendCaller';
import { MembershipRepository } from '@/fairplayer/domain/membership/MembershipRepository';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';
import { BackendCheckoutSession, toCheckoutSession } from '@/fairplayer/secondary/BackendCheckoutSession';
import { MembershipToCreate } from '@/fairplayer/domain/membership/MembershipToCreate';
import { fromMembershipToCreate } from '@/fairplayer/secondary/membership/BackendMembershipToCreate';

export class BackendMembershipRepository implements MembershipRepository {
  constructor(private backendCaller: BackendCaller) {}

  async create(membershipToCreate: MembershipToCreate): Promise<CheckoutSession> {
    return this.backendCaller
      .post<BackendCheckoutSession>(`api/clubs/${membershipToCreate.clubSlug}/memberships`, fromMembershipToCreate(membershipToCreate))
      .then(checkoutSession => toCheckoutSession(checkoutSession.data));
  }
}

import { InjectionKey } from 'vue';

export const pageRedirecterKey: InjectionKey<PageRedirecter> = Symbol();

export class PageRedirecter {
  constructor(private window: Window) {}

  async navigateTo(redirectUrl: string): Promise<void> {
    this.window.location.href = redirectUrl;
  }
}
